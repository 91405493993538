let Thematiques

Thematiques =  [
    {value: 'Adulte & Charme', categorie: 'Adulte & Charme' , icon: 'fas fa-heart'},
    {value: 'Agence', categorie: 'Agence',  icon: 'fas fa-laptop-house'},
    {value: 'Nourriture et Boisson', categorie: 'Aliments', icon: 'fas fa-lemon'},
    {value: 'Animaux', categorie: 'Animaux', icon: 'fas fa-paw'},
    {value: 'Assurance', categorie: 'Assurance', icon: 'fas fa-shield-alt'},
    {value: 'Audiovisuel', categorie: 'Audiovisuel', icon: 'fas fa-tv'},
    {value: 'Automobile', categorie: 'Automobile', icon: 'fas fa-car'},
    {value: 'Business To Business', categorie: 'B2B', icon: 'fas fa-people-arrows'},
    {value: 'e-commerce', categorie: 'E-commerce', icon: 'fas fa-shopping-basket'},
    {value: 'Emplois et formation', categorie: 'Emplois & Formation', icon: 'fas fa-graduation-cap'},
    {value: 'Énergie & Eau', categorie: 'Énergie & Eau', icon: 'fas fa-fire'},
    {value: 'Finance', categorie: 'Finance & Crédit', icon: 'fas fa-piggy-bank'},
    {value: 'Maison et Jardin', categorie: 'Immobilier', icon: 'fas fa-building'},
    {value: 'Maison et Jardin', categorie: 'Jardin', icon: 'fas fa-tree'},
    {value: 'Gaming', categorie: 'Jeux', icon: 'fas fa-gamepad'},
    {value: 'Lingerie & Charme', categorie: 'Lingerie & Charme', icon: 'fas fa-kiss-wink-heart'},
    {value: 'Livres & Presse', categorie: 'Livres & Presse', icon: 'fas fa-book'},
    {value: 'Maison et Jardin', categorie: 'Logement', icon: 'fas fa-house-user'},
    {value: 'Luxe', categorie: 'Luxe', icon: 'fas fa-glass-cheers'},
    {value: 'Musique et produits culturels', categorie: 'Musique & Produits culturels', icon: 'fas fa-music'},
    {value: 'Mobile', categorie: 'Opérateur mobile & internet', icon: 'fas fa-wifi'},
    {value: 'Photo & Vidéo', categorie: 'Photo & Vidéo', icon: 'fas fa-photo-video'},
    {value: 'Sante et Beaute', categorie: 'Produits pharmaceutiques', icon: 'fas fa-first-aid'},
    {value: 'Reseaux sociaux et Rencontres', categorie: 'Rencontre', icon: 'fas fa-venus-mars'},
    {value: 'Sante et Beaute', categorie: 'Santé & Beauté', icon: 'fas fa-weight'},
    {value: 'Sports / Loisirs', categorie: 'Sports & Loisirs', icon: 'fas fa-running'},
    {value: 'Tourisme / Loisirs', categorie: 'Tourisme', icon: 'fas fa-suitcase-rolling'},
    {value: 'Shopping et Ventes', categorie: 'Ventes privées & Enchères', icon: 'fas fa-gavel'},
    {value: 'Nourriture et Boisson', categorie: 'Vin, Spiritueux & Tabac', icon: 'fas fa-wine-bottle'},
    {value: 'Voyance', categorie: 'Voyance', icon: 'fas fa-dice'}
]

export {
  Thematiques
}
