<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>LISTE DES CAMPAGNES</h3>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8 col-lg-12 col-12">
                <div class="row">
                  <div class="col-md-3">
                    <base-input
                      label="subid"
                      v-model="id_campagne"
                      name="id_campagne"
                      :error="getError('id_campagne')"
                    ></base-input>
                  </div>
                  <!-- <div class="col-md-3">
                    <base-input
                      label="bases"
                      v-model="nom_base"
                      name="nom_base"
                      :error="getError('nom_base')"
                    ></base-input>
                  </div> -->

                  <!-- <div class="col-md-3">
                    <base-input label="CAMPAGNES">
                      <el-select
                        filterable
                        :disabled="$apollo.queries.campagnes.loading"
                        class="select-primary"
                        placeholder="Choisissez"
                        v-model="campagnesListe.campagne"
                      >
                        <el-option class="select-primary" value label></el-option>
                        <el-option
                          v-for="option in campagnesListe.listeCampagnes"
                          class="select-primary"
                          :value="option.id_programme"
                          :label="option.provenance"
                          :key="option.id_programme"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div> -->
                </div>
              </div>
              <div class="col-xl-4 col-lg-12 col-12">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <base-button
                    v-if="$apollo.queries.campagnes.loading"
                    loading
                    round
                    class="btn btn-round btn-just-icon btn-primary mb-2"
                  >Chargement</base-button>
                  <button
                    v-else
                    @click="resetFilter"
                    class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                  >RÉINITIALISER</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card>
          <div class="card-body">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary mb-3 pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6">
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>
              <el-table :data="filterlistCampagnes" row-class-name="booking-table-rows">
                <el-table-column
                  prop="nom_campagne"
                  label="CAMPAGNE"
                  class="table-striped"
                  min-width="400"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column
                  prop="programme.Titre"
                  label="Programme"
                  class="table-striped"
                  min-width="150"
                  sortable
                  show-overflow-tooltip
                > 
                </el-table-column>

                <el-table-column
                  prop="support.nom_support"
                  label="Support"
                  class="table-striped"
                  min-width="130"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column
                  prop="support.support_types"
                  label="types support"
                  class="table-striped"
                  min-width="150"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    <template v-if='scope.row.support'>
                      <template v-if="scope.row.support.support_types">
                        <template v-for="type in scope.row.support.support_types.split(';')">
                          {{ supportTypes.find(c => c.value == type).type }} 
                          <label v-if="scope.row.support.support_types.split(';').length > 1">,</label>
                        </template>
                      </template>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="id_campagne"
                  label="Subid"
                  class="table-striped"
                  min-width="95"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column
                  prop="prix_editeur"
                  label="Prix lead"
                  class="table-striped"
                  min-width="119"
                  sortable
                  show-overflow-tooltip
                >
                  <template
                    scope="props"
                  >{{ (props.row.prix_editeur > 0 ? formatThounsends(props.row.prix_editeur) : 0) + ' €' }}</template>
                </el-table-column>

                <el-table-column label="Stats" min-width="75">
                  <div slot-scope="props">
                    <el-tooltip content="STATISTIQUES" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="openStats(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-chart-bar-32"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

                <el-table-column label="Visuel" min-width="75">
                  <div slot-scope="props">
                    <el-tooltip content="VISUEL" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="openVisuel(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-tv-2"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>

              <!-- <modal
                :show.sync="showExtractModal"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-listbase"
              >
                <card
                  type="secondary"
                  header-classes="bg-white pb-6"
                  body-classes="px-lg-6 py-lg-6"
                  class="border-0 mb-0"
                >
                  <template>
                    <div class="text-muted text-center mb-5">
                      <h3 class="modal-title">EXTRACTION DEPUIS UN TAG</h3>
                    </div>
                    <div class="text-center text-muted mb-4">
                      <h5 class="modal-title">TAG DE CAMPAGNE:</h5>
                      <span
                        class="mb-3"
                        style="color: #988fc3; font-size: 15px;"
                      >{{ extractTag.nom_campagne }}</span>
                    </div>
                    <form role="form">
                      <div class="col-md-8">
                        <base-input label="DATE DÉBUT:">
                          <el-date-picker
                            v-model="extractTag.start_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="col-md-8">
                        <base-input label="DATE FIN:">
                          <el-date-picker
                            v-model="extractTag.end_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="text-center">
                        <base-button type="info" class="my-4" @click="extract()">EXTRAIRE</base-button>
                      </div>
                    </form>
                  </template>
                </card>
              </modal> -->

            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import { Table, TableColumn } from "element-ui";
import { BasePagination } from "src/components";
import Fuse from "fuse.js";
import gql from "graphql-tag";
import swal from "sweetalert2";
import { Modal } from "src/components";

let today = new Date();

export default {
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    Modal
  },
  apollo: {
    // Filter: {
    //   query: gql`
    //     query {
    //       programmes_campagnes {
    //         id_programme
    //         provenance
    //       }
    //     }
    //   `,
    //   update: data => data,
    //   result({ data, loader, networkStatus }) {
    //     this.campagnesListe.listeCampagnes = data.programmes_campagnes;
    //   }
    // },
    campagnes: {
      query: gql`
        query(
          $id_client: Int!
          $id_programme: Int
          $nom_base: String
          $sub_id: Int
        ) {
          liste_campagnes(
            id_client: $id_client
            id_programme: $id_programme
            nom_base: $nom_base
            sub_id: $sub_id
          ) {
            id_client
            nom_campagne
            id_campagne
            id_programme
            prix_editeur
            identifiant
            bloque
            programme {
              Titre
            }
            base {
              nom_base
            }
            support {
              nom_support
              support_types
            }
          }
        }
      `,
      update: data => data.liste_campagnes,
      skip() {
        return this.skiplistMember;
      },
      result({ data, loader, networkStatus }) {
        this.tableData = data.liste_campagnes;
        this.total = data.liste_campagnes.length;
        this.skiplistMember = true;
      },
      variables() {
        return {
          id_client:  JSON.parse(localStorage.getItem("utilisateur")).id_clien_myaffil,
          id_programme: this.campagnesListe.campagne,
          nom_base: this.nom_base,
          sub_id: this.id_campagne
        };
      }
    }
  },
  data() {
    return {
      // extractTag: {
      //   start_date: new Date()
      //     .toJSON()
      //     .slice(0, 10)
      //     .replace(/-/g, "-"),
      //   end_date: new Date()
      //     .toJSON()
      //     .slice(0, 10)
      //     .replace(/-/g, "-"),
      //   nom_campagne: ""
      // },
      showExtractModal: false,
      id_user_connected: null,
      skiplistMember: false,
      id_client: null, //rm
      id_programme: null,
      nom_base: null,
      id_campagne: null,
      campagnesListe: {
        campagne: "",
        listeCampagnes: []
      },
      total: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      searchedData: [],
      tableData: [],
      supportTypes: [
        {value: '1', type:'APPLI MOBILE'},
        {value: '2', type:'BASE EMAILS'},
        {value: '3', type:'BASE SMS'},
        {value: '4', type:'COMPARATEUR DE PRIX'},
        {value: '5', type:'DISPLAY'},
        {value: '6', type:'EMULATION'},
        {value: '7', type:'FACEBOOK'},
        {value: '8', type:'INSTAGRAM'},
        {value: '9', type:'LINKEDIN'},
        {value: '10', type:'PUSH NOTIFS'},
        {value: '11', type:'RETARGETING'},
        {value: '12', type:'SNAPCHAT'},
        {value: '13', type:'SEO/SEA'},
        {value: '14', type:'TWITTER'},
        {value: '15', type:'YOUTUBE'}
      ],
    };
  },
  mounted() {
    (this.id_user_connected = JSON.parse(localStorage.getItem("utilisateur")).id),
      (this.campagnesListe.campagne = this.$route.params.id_programme),
      (this.id_programme = this.$route.params.id_programme), //rm
      (this.nom_base = this.$route.params.nom_base),
      (this.sub_id = this.$route.params.id_campagne),
      (this.id_client = this.$route.params.id_client);
  },
  computed: {
    filterlistCampagnes() {
      let list = this.tableData
      if (this.id_campagne != null) {
        list =  this.tableData.filter(campagne => {
          return campagne.id_campagne.match(this.id_campagne)
        })
        this.total = list.length;
        if(list != null)
          return list.slice(this.from, this.to)
      }
      return list.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    resetFilter() {
      (this.campagnesListe.campagne = ""),
        (this.nom_base = ""),
        (this.id_campagne = "");
    },
    openStats(row) {
      let routeData = this.$router.resolve({
        name: "Stats Shoots My Affil Editeur"
      });
      localStorage.setItem("id_campagne", row.id_campagne)
      window.open(routeData.href, "_blank");
    },
    openVisuel(row) {
      let routeData = this.$router.resolve({
        name: "liste campagne visuel My Affil Editeur"
      });
      let params = {
        identifiant: row.identifiant,
        nom_campagne: row.nom_campagne,
        id_programme: row.id_programme
      }
      localStorage.setItem('params',JSON.stringify(params))
      window.open(routeData.href, "_blank");
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
  }
};
</script>

<style>

  .booking-table-rows {
    text-transform: uppercase;
  }

</style>