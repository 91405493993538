<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-5 text-left">
                <h2 class="card-title">STATISTIQUES PAR CAMPAGNE</h2>
              </div>
              <div class="col-md-12">
                <div class="row">

                  <base-input class="col-md-3">
                    <el-select filterable class="select-primary"
                      placeholder="PAYS"
                      v-model="Pays"
                      filterable
                      multiple
                      collapse-tags
                      @change="changePaysSelect"
                    >
                      <el-option 
                        class="select-primary"
                        value="ALL_SELECT"
                        label="TOUT SÉLECTIONNER"
                      >
                      </el-option>
                      <hr/>
                      <el-option 
                        v-for="option in listPays"
                        class="select-primary"
                        :value="option.iso2"
                        :label="option.name.toUpperCase()"
                        :key="option.iso2">
                      </el-option>
                    </el-select>
                  </base-input>

                  <base-input class="col-md-3">
                    <el-select
                      class="select-primary"
                      placeholder="THÉMATIQUE"
                      v-model="ThematiqueSelects.Thematique"
                      filterable
                      multiple
                      collapse-tags
                      @change="changeThematiqueSelect"
                    >
                      <el-option 
                        class="select-primary"
                        value="ALL_SELECT"
                        label="TOUT SÉLECTIONNER"
                      >
                      </el-option>
                      <hr/>
                      <el-option
                        v-for="option in ThematiqueSelects.ListThematique"
                        class="select-primary"
                        :value="option.value"
                        :label="option.categorie.toUpperCase()"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>

                  <base-input class="col-md-3">
                    <el-select
                      class="select-primary"
                      placeholder="SUPPORT"
                      v-model="Support"
                      filterable
                      multiple
                      collapse-tags
                      @change="changeSupportSelect"
                    >
                      <el-option 
                        class="select-primary"
                        value="ALL_SELECT"
                        label="TOUT SÉLECTIONNER"
                      >
                      </el-option>
                      <hr/>
                      <el-option
                        v-for="option in liste_supports"
                        class="select-primary"
                        :value="option.id_bases"
                        :label="option.nom_support.toUpperCase()"
                        :key="option.id_bases">
                      </el-option>
                    </el-select>
                  </base-input>

                  <base-input class="col-md-3">
                    <el-select class="select-primary"
                        placeholder="PÉRIODE"
                        v-model="PeriodeSelects.Period">
                        <el-option 
                        v-for="option in PeriodeSelects.ListPeriod"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.value">
                      </el-option>
                    </el-select>
                  </base-input>

                  <template v-if="PeriodeSelects.Period === 'periode'">
                    <base-input class="col-md-3">
                      <el-date-picker 
                        v-model="StartDate" 
                        type="date" 
                        value-format="yyyy-MM-dd" 
                        placeholder="DATE DE DÉBUT"
                      ></el-date-picker>
                    </base-input>

                    <base-input class="col-md-3">
                      <el-date-picker 
                        v-model="EndDate" 
                        type="date" 
                        value-format="yyyy-MM-dd" 
                        placeholder="DATE DE FIN"
                      ></el-date-picker>
                    </base-input>
                  </template>

                  <div class="col-md-12 float-right">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="$apollo.queries.campagneAf.loading" 
                        loading 
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button 
                        class="btn btn-round btn-just-icon btn-primary"
                        v-else
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="p-2" data="black">
            <el-table :data="campagneAf" v-loading="$apollo.queries.campagneAf.loading" show-summary :summary-method="getSummaries" row-class-name="booking-table-rows" class="customtable_statsCampEdit">
              <el-table-column
                label="campagne"
                prop="campagne"
                show-overflow-tooltip
                sortable
                min-width="130"
              ></el-table-column>
              <el-table-column
                label="pays"
                prop="pays"
                show-overflow-tooltip
                sortable
                min-width="90"
              >
                <template scope="scope">
                {{ GetPaysName(scope.row.pays) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Thématique"
                prop="thematique"
                show-overflow-tooltip
                sortable
                min-width="135"
              >
              </el-table-column>
              <el-table-column
                label="type"
                prop="type"
                show-overflow-tooltip
                sortable
                min-width="90"
              >
                <template scope="scope">
                  <template v-if="scope.row.type === 'lead'">
                    CPL
                  </template>
                  <template v-else-if="scope.row.type === 'affichage'">
                    CPC
                  </template>
                  <template v-else-if="scope.row.type === 'cpm'">
                    CPM
                  </template>
                  <template v-else-if="scope.row.type === 'cpa'">
                    CPA
                  </template>
                  <template v-else-if="scope.row.type === 'sms'">
                    SMS
                  </template>
                  <template v-else-if="scope.row.type === 'téléchargement'">
                    CPD
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="impression"
                prop="impression"
                show-overflow-tooltip
                sortable
                min-width="130"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.impression) }}
                </template>
              </el-table-column>
              <el-table-column
                label="clics"
                prop="clics"
                show-overflow-tooltip
                sortable
                min-width="95"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.clics) }}
                </template>
              </el-table-column>
              <el-table-column
                label="leads"
                prop="leads"
                show-overflow-tooltip
                sortable
                min-width="95"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.leads) }}
                </template>
              </el-table-column>
              <el-table-column
                label="ventes"
                prop="ventes"
                show-overflow-tooltip
                sortable
                min-width="105"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ventes) }}
                </template>
              </el-table-column>
              <el-table-column
                label="ctr"
                prop="ctr"
                show-overflow-tooltip
                sortable
                :sort-method="CustomSort_ctr"
                min-width="85"
              >
                <template scope="scope">
                  {{ scope.row.ctr + " %" }}
                </template>
              </el-table-column>
              <el-table-column
                label="ca"
                prop="ca"
                show-overflow-tooltip
                sortable
                min-width="90"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true, 2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="ecpc"
                prop="ecpc"
                show-overflow-tooltip
                sortable
                min-width="100"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ecpc, true, 2) + ' €' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Select, Option, DatePicker } from "element-ui";
  import { Table, TableColumn } from 'element-ui';
  import gql from 'graphql-tag'
  import { Thematiques } from "src/pages/Filters/Thematiques";

  let CurentDate = new Date()

  export default {
    watch: {
      "PeriodeSelects.Period"(value) {
        if (value === "YESTERDAY") {
          let valueDate = CurentDate;
          valueDate.setDate(valueDate.getDate()-1)
          this.periode = 'periode'
          this.StartDate = valueDate.getFullYear() +"-"+ this.appendLeadingZeroes(valueDate.getMonth()+1) +"-"+ this.appendLeadingZeroes(valueDate.getDate())
          this.EndDate = valueDate.getFullYear() +"-"+ this.appendLeadingZeroes(valueDate.getMonth()+1) +"-"+ this.appendLeadingZeroes(valueDate.getDate())
        } else if (value === "jour") {
          this.periode = 'jour'
        } else if (value === "mois") {
          this.periode = 'mois'
        } else if (value === "mois_dernier") {
          this.periode = 'mois_dernier'
        } else if (value === "annee") {
          this.periode = 'annee'
        } else{
          this.periode = value
        }
      }
    },
    apollo: {
      campagneAf: {
        query: gql`
          query($id_client: Int!, $periode: String, $date_debut: String, $date_fin: String, $categories: [String], $pays: [String], $bases: [String]) {
            campagneAf (id_client: $id_client,periode: $periode, date_debut: $date_debut, date_fin: $date_fin, categories: $categories, pays: $pays, bases: $bases){
              campagne 
              pays 
              thematique
              type
              impression 
              clics
              leads 
              ventes 
              ctr 
              ca
              ecpc
            }
          }
        `,
        skip() {
          return this.skipQuery
        },
        variables() {
          return {
            id_client: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil,
            periode: this.periode,
            date_debut: this.StartDate,
            date_fin: this.EndDate,
            categories: this.ThematiqueSelects.Thematique, 
            pays: this.Pays, 
            bases: this.Support
          };
        },
        result({ data, loader, networkStatus }) {
          this.skipQuery = true
        },
        fetchPolicy: 'network-only'
      },
      liste_supports: {
        query: gql`
          query($id_client: ID) {
            liste_supports(id_client: $id_client, orderBy: [
                {
                  field: "id_support"
                  order: DESC
                }
            ]) {
              id_support
              id_bases
              nom_support
            }
          }
        `,
        update: data => data.liste_supports,
        result({ data, loader, networkStatus }) {
        },
        variables() {
          return {
            id_client: JSON.parse(localStorage.getItem('utilisateur')).id
          };
        }
      },
      listPays: {
        query: gql`
          query {
            listPays(iso2:""){
              iso2
              name
            }
          }
        `,
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker
    },
    data() {
      return {
        skipQuery: true,
        PaysAllSelect: false,
        ThematiqueAllSelect: false,
        SupportAllSelect: false,
        Pays: [],
        ThematiqueSelects: {
          Thematique: "",
          ListThematique: Thematiques
        },
        Support: [],
        PeriodeSelects: {
          Period: '',
          ListPeriod: [
            { value: "periode" , label: "PÉRIODE"},
            { value: "jour" , label: "AUJOURD'HUI"},	
            { value: "YESTERDAY" , label: "HIER"},	
            { value: "mois" , label: "MOIS EN COURS"},
            { value: "mois_dernier" , label: "MOIS DERNIER"},
            { value: "annee" , label: "ANNÉE EN COURS"},
            { value: "" , label: "DEPUIS LA CRÉATION DU COMPTE"}
          ]
        },
        periode: null,
        EndDate: null,
        StartDate: null,
      }
    },
    methods: {
      CustomSort_ctr(a, b) {
        try {
          return parseFloat(a.ctr.replace(",",".")) - parseFloat(b.ctr.replace(",","."))
        } catch (error) {
          return false
        }
      },
      send() {
        this.skipQuery = false
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value : value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      GetPaysName(iso) {
        let findpay = this.listPays.find(t => t.iso2 === iso);
        if (findpay) {
          return findpay.name
        }
        return ""
      },
      changePaysSelect(value) {
        const allValues = []
        for (const item of this.listPays) {
          allValues.push(item.iso2)
        }

        if (value.includes('ALL_SELECT')) {
          if (this.PaysAllSelect = !this.PaysAllSelect)
            this.Pays = allValues;
          else
            this.Pays = []
        }
      },
      changeThematiqueSelect(value) {
        const allValues = []
        for (const item of this.ThematiqueSelects.ListThematique) {
          allValues.push(item.value)
        }

        if (value.includes('ALL_SELECT')) {
          if (this.ThematiqueAllSelect = !this.ThematiqueAllSelect)
            this.ThematiqueSelects.Thematique = allValues;
          else
            this.ThematiqueSelects.Thematique = []
        }
      },
      changeSupportSelect(value) {
        const allValues = []
        for (const item of this.liste_supports) {
          allValues.push(item.id_bases)
        }

        if (value.includes('ALL_SELECT')) {
          if (this.SupportAllSelect = !this.SupportAllSelect)
            this.Support = allValues;
          else
            this.Support = []
        }
      },
      getSummaries(param) {
        const { columns, data } = param;
        let somme_imp = 0
        let somme_clics = 0
        let somme_leads = 0
        let somme_ventes = 0
        let somme_ca = 0
        data.forEach(stats => {
          somme_imp += stats.impression
          somme_clics += stats.clics
          somme_leads += stats.leads
          somme_ventes += stats.ventes
          somme_ca += stats.ca
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 4) {
            sums[index] = this.formatThounsends(somme_imp);
            return;
          } else if (index === 5) {
            sums[index] = this.formatThounsends(somme_clics);
            return;
          } else if (index === 6) {
            sums[index] = this.formatThounsends(somme_leads);
            return;
          } else if (index === 7) {
            sums[index] = this.formatThounsends(somme_ventes);
            return;
          } else if (index === 9) {
            sums[index] = this.formatThounsends(somme_ca, true, 2) + " €";
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      appendLeadingZeroes(n){
        if(n <= 9){
          return "0" + n;
        }
        return n
      },
    },
  }
</script>

<style>

  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_statsCampEdit .cell{
    word-break: break-word;
    font-size: 11.5px;
  }

</style>