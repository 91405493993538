<template>
  <div class="content">
    <h3>{{ "liste des scénarios".toUpperCase() }}</h3>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-end">
          <div class="col-xl-8 col-lg-12 col-12">
            <div class="row">
              <div class="col-md-3">
                <base-input>
                  <el-select
                    :placeholder="'Compte'.toUpperCase()"
                    :disabled="loading.accounts"
                    class="select-primary"
                    v-model="accounts.activeAccountId"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="account in accounts.list"
                      class="select-primary"
                      :value="account.id"
                      :label="(account.society).toUpperCase()"
                      :key="account.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
               <div class="col-md-3">
                <base-input>
                  <el-select
                    :placeholder="'Produit'.toUpperCase()"
                    :disabled="loading.scenarios"
                    class="select-primary"
                    v-model="activeProduct"
                    filterable
                    clearable
                  >
                    <el-option
                      class="select-primary"
                      :label="'Tous les produits'.toUpperCase()"
                      value="all"
                    >
                    </el-option>
                    <el-option
                      v-for="product in products"
                      class="select-primary"
                      :value="product"
                      :label="product.toUpperCase()"
                      :key="product"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card listScenarios">
      <div class="card-body">
        <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle" class="btn-link" type="market" size="sm" icon>
          <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
        </base-button>
        <div class="col-lg-4 col-sm-4 mb-3">
          <button
            class="btn btn-notif"
            @click="launchAllScenarios()"
            v-show="filteredScenarios.length > 0"
          >Lancer tous les scénarios</button>
        </div>
        <el-table
          :data="filteredScenarios"
          v-loading="loading.scenarios"
          row-class-name="booking-table-rows"
          class="customtable_cmd"
        >
          <el-table-column
            prop='id'
            label="Id"
            minWidth='40'
          >
            <template scope="scope">
              {{ ++scope.$index }}
            </template>
          </el-table-column>
          <el-table-column
            prop='description'
            label="Scénario"
            minWidth='140'
            sortable
          >
            <template scope="scope">
              <template v-if="scope.row.description.length <= 41">
                {{ scope.row.description }}
              </template>
              <template v-else>
                <el-tooltip
                  :content="scope.row.description"
                  :open-delay="300"
                  effect="light"
                  placement="top"
                  popper-class="scenario-tooltip"
                >
                  <label>
                    {{ scope.row.description.slice(0,38) }}...
                  </label>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop='lastExecutionDate'
            label="Dernière exécution"
            minWidth='100'
          >
            <template scope="scope">
              {{ scope.row.lastTest.executionDate ? formatDate(scope.row.lastTest.executionDate) : '-' }}
            </template>
          </el-table-column>
          <el-table-column
            prop='statut'
            label="Statut"
            minWidth='85'
          >
            <template scope="scope">
              <span :class="{'text-danger': scope.row.lastTest.errorMessage}">
                {{ scope.row.lastTest.status ? scope.row.lastTest.status : '-' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop='cronTask'
            label='Tâche cron'
            minWidth='85'
            sortable
          >
            <template scope="scope">
              <base-switch
                v-model="scope.row.isActive"
                type="primary"
                on-text="ON"
                off-text="OFF"
                @input="(value) => toggleCronTask(scope.row)"
              >
              </base-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="Action"
            minWidth='120'
          >
            <template scope="scope">
              <el-tooltip
                content="LANCER"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <base-button
                  @click="launchScenario(scope.row)"
                  class="btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="fas fa-play"></i>
                </base-button>
              </el-tooltip>
              <el-tooltip
                :content="$t('buttons.BtnModify').toUpperCase()"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <base-button
                  @click.native="Edit(scope.row)"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
              </el-tooltip>
              <el-tooltip
                v-show="scope.row.lastTest.screenshotURL"
                :content="$t('buttons.BtnView').toUpperCase()"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <base-button
                  @click.native="View(scope.row)"
                  class="like btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-tv-2"></i>
                </base-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <test-details-modal
      v-if="showedTest"
      :title="showedTest.scenarioDetails.description"
      :imageUrl="showedTest.screenshotURL"
      @close="() => showedTest = null"
    >
      <template>
        <div class="form-row">
          <label class="col-md-12">
            <b> Date d'exécution : </b>{{ formatDate(showedTest.executionDate) }}
          </label>
        </div>
        <div class="form-row">
          <label class="col-md-12">
            <b> Statut : </b>{{ showedTest.status }}
          </label>
        </div>
        <div class="form-row">
          <label class="col-md-12">
            <b> Durée : </b>{{ showedTest.elapsedTime }}
          </label>
        </div>
        <div class="form-row">
          <label class="col-md-12">
            <b> Étape : </b>{{ showedTest.step }}
          </label>
        </div>
        <div class="form-row" v-if="showedTest.errorMessage">
          <label class="col-md-12">
            <b> Message d'erreur : </b>{{ showedTest.errorMessage }}
          </label>
        </div>
        <div class="form-row">
          <label class="col-md-12 label-break-all">
            <b> Url : </b>{{ showedTest.scenarioDetails.formUrl }}
          </label>
        </div>
      </template>
    </test-details-modal>

    <edit-scenario-modal
      v-if="scenarioToEdit"
      :scenario="scenarioToEdit"
      @edited="onScenarioEdit()"
      @close="resetScenarioToEdit()"
    >
    </edit-scenario-modal>
  </div>
</template>

<script>

import { Select, Option, Table, TableColumn } from 'element-ui'
import { BaseSwitch } from 'src/components/index'
import Swal from 'sweetalert2'
import PerfectScrollbar from 'perfect-scrollbar'

import moment from 'moment'
moment.locale(localStorage.getItem('lang') == "en" ? "en" : "fr")

import { API_URL, addAuthUserIfItDoesntExist, getAuthUserScenarios, getAuthUserAccounts }  from 'src/pages/GlobalWeedoCheck/API'
import TestDetailsModal from 'src/pages/GlobalWeedoCheck/modals/TestDetailsModal'
import EditScenarioModal from 'src/pages/GlobalWeedoCheck/modals/EditScenarioModal.vue'

const DEFAULT_PRODUCT = 'all'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseSwitch,
    TestDetailsModal,
    EditScenarioModal
  },
  data() {
    return {
      fullscreen: false,
      PerfectScrollbar: null,
      loading: {
        accounts: true,
        scenarios: true
      },
      accounts: {
        activeAccountId: null,
        list: []
      },
      scenarios: [],
      activeProduct: DEFAULT_PRODUCT,
      showedTest: null,
      scenarioToEdit: null
    }
  },
  computed: {
    filteredScenarios() {

      let scenariosOfCurrentAccount = this.getScenariosOfCurrentAccount()

      if(this.activeProduct === DEFAULT_PRODUCT) return scenariosOfCurrentAccount

      return scenariosOfCurrentAccount.filter(s => s.product === this.activeProduct)
    },
    products() {

      let scenariosOfCurrentAccount = this.getScenariosOfCurrentAccount()

      return Array.from(new Set(scenariosOfCurrentAccount.map(scenario => scenario.product)))
    }
  },
  watch: {
    'accounts.activeAccountId'(oldVal, newVal) {
      this.activeProduct = DEFAULT_PRODUCT
    }
  },
  async created() {

    try {

      await addAuthUserIfItDoesntExist()

      getAuthUserAccounts()
        .then(accounts => {
          this.accounts.list = accounts
          this.accounts.activeAccountId = this.accounts.list[0] ? this.accounts.list[0].id : null

          this.disableAccountsLoading()
        })

      this.getScenarios()

    } catch (error) {

      this.disableAccountsLoading()
      this.disableScenariosLoading()
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).calendar()
    },
    getScenariosOfCurrentAccount() {
      return this.scenarios.filter(scenario => scenario.userId == this.accounts.activeAccountId)
    },
    toggle () {
      this.$fullscreen.toggle(this.$el.querySelector('.listScenarios'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen
      if (fullscreen) {
        this.PerfectScrollbar = new PerfectScrollbar(".listScenarios");
      }
      else {
        this.PerfectScrollbar.destroy();
        this.PerfectScrollbar = null
      }
    },
    View(scenario) {
      if(this.fullscreen) return
      this.showedTest = scenario.lastTest
      this.showedTest['scenarioDetails'] = scenario
    },
    Edit(scenario) {
      if(this.fullscreen) return
      this.scenarioToEdit = {...scenario}
    },
    resetScenarioToEdit() {
      this.scenarioToEdit = null
    },
    onScenarioEdit() {
      this.resetScenarioToEdit()
      this.getScenarios()
    },
    toggleCronTask(scenario) {

      const apiUrl = `${API_URL}/scenarios/${scenario.name}`
      const apiToken = localStorage.getItem('token_jwt')

      this.axios.put(
        apiUrl,
        {
          "isActive": scenario.isActive
        },
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`,
          }
        }
      )
        .then(response => {
          const committedAction = scenario.isActive ? 'activé': 'désactivé'
          this.$notify({type: 'success', message: `Tâche cron ${committedAction}`})
        })
        .catch(error => {
          let errorMessage = ""

          try {
            errorMessage = error.response.data.message
          } catch (error) {
            errorMessage = "Une erreur est survenue...réessayer plus tard"
          }
          this.$notify({type: 'warning', message: errorMessage })
        })
    },
    launchScenario(scenario) {

      const apiUrl = `${API_URL}/users/${this.accounts.activeAccountId}/scenarios/${scenario.product}/${scenario.name}`
      const swalTitle = `Lancement du scénario <br> ${scenario.description}`

      this.Launch(apiUrl, swalTitle)
    },
    launchAllScenarios() {

      const apiUrl = `${API_URL}/users/${this.accounts.activeAccountId}/scenarios/${this.activeProduct}`
      const swalTitle = "Lancement de tous les scénarios"

      this.Launch(apiUrl, swalTitle)
    },
    Launch(apiUrl, swalTitle) {

      if(this.fullscreen) return

      swalTitle = '<span style="font-size: 0.8em !important;">'+swalTitle+'</span>'

      const self = this

      Swal.fire({
        width: '600px',
        title: swalTitle,
        confirmButtonColor: '#00c09b',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'LANCER',
        cancelButtonText: 'ANNULER',
        showCancelButton: true,
        allowOutsideClick: () => true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return self.callApiToLaunchScenario(apiUrl)
        }
      })
    },
    async callApiToLaunchScenario(apiUrl) {

      Swal.enableButtons()

      const apiToken = localStorage.getItem('token_jwt')

      return this.axios.post(
        apiUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`,
          }
        }
      )
      .then(response => {

        this.getScenarios()

        const message = response.data.message

        this.$notify({ type: 'success', message })

        return response
      })
      .catch(({ response }) => {

        let errorMessage = ""

        try {

          errorMessage = response.data.message

        } catch (error) {

          errorMessage = "Une erreur est survenue...réessayer plus tard"
        }

        Swal.showValidationMessage(errorMessage)
      })

    },
    getScenarios() {

      this.activateScenariosLoading()

      getAuthUserScenarios()
        .then(scenarios => {
          this.scenarios = scenarios
          this.disableScenariosLoading()
        }).catch(error => this.disableScenariosLoading())
    },
    activateScenariosLoading() {
      this.loading.scenarios = true
    },
    disableScenariosLoading() {
      this.loading.scenarios = false
    },
    disableAccountsLoading() {
      this.loading.accounts = false
    }
  }
}

</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmd .cell{
    word-break: break-word;
  }
  .el-table .caret-wrapper {
    width: auto
  }
  .scenario-tooltip {
    font-size: 15px;
  }
</style>
