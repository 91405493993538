<template>
  <modal
    :show.sync="isActive"
    body-classes="p-0"
    class="test-details-modal modal-listbase"
    :modalClasses="modalClasses"
    :centered="true"
  >
    <card
      type="secondary"
      header-classes="bg-white pb-6"
      body-classes="px-lg-10 py-lg-10"
      class="border-0 mb-0"
    >
      <base-button style="float: right;" @click.native="closeModal()" class="like btn-link" type="danger" size="sm" icon>
        <i class="tim-icons icon-simple-remove"></i>
      </base-button>
      <center>
        <h4>{{ title }}</h4>
      </center>
      <hr />
      <slot></slot>
      <div class="form-row mt-2">
        <label class="col-md-12" v-show="imageLoaded">
          <img
            :src="imageUrl"
            class="image-fluid border w-100"
            @load="onImageLoad()"
            @error="onImageError()"
          >
        </label>
        <label class="col-md-12 text-center" v-show="imageLoading">
          <base-button
            loading
            round
            type="primary"
            class="btn btn-round btn-just-icon btn-primary mb-2"
            >Chargement de l'image...</base-button>
        </label>
        <label class="col-md-12 text-center" v-show="imageLoadError">
          Un problème est survenu lors du chargement de l'image...réessayez plus tard
        </label>
      </div>
    </card>
  </modal>
</template>

<script>

import { Modal } from "src/components"

export default {
  components: {
    Modal
  },
  props: {
    title: {
      Type: String,
      required: true
    },
    imageUrl: {
      Type: String,
      default: ""
    },
    modalClasses: {
      Type: [String, Object],
      required: false
    }
  },
  data() {
    return {
      imageLoading: true,
      imageLoadError: false,
      isActive: true
    }
  },
  watch: {
    isActive(newValue, oldValue) {
      if(!newValue) {
        this.closeModal()
      }
    }
  },
  computed: {
    imageLoaded() {
      return !this.imageLoading && !this.imageLoadError
    }
  },
  methods: {
    onImageLoad() {
      setTimeout(() => {
        this.imageLoading = false
      },200)
    },
    onImageError() {
      this.imageLoading = false
      this.imageLoadError = true
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style>
  .test-details-modal {
    overflow-y: auto !important;
  }

  .test-details-modal .content h4 {
    margin-bottom: 5px;
  }

  .test-details-modal.show .modal-dialog {
    transform: none;
  }

  .test-details-modal .modal-dialog {
    max-width: 890px;
  }

  .label-break-all {
    word-break: break-all;
  }
</style>
