<template>
  <Modal_selfEvent
    :show.sync="isActive"
    body-classes="p-0"
    class="edit-incident-modal modal-listbase"
    :modalClasses="modalClasses"
    :centered="true"
  >
    <card
      type="secondary"
      header-classes="bg-white pb-6"
      body-classes="px-lg-10 py-lg-10"
      class="border-0 mb-0"
    >
      <base-button style="float: right;" @click.native="closeModal()" class="like btn-link" type="danger" size="sm" icon>
        <i class="tim-icons icon-simple-remove"></i>
      </base-button>
      <center>
        <h4>Modifier Incident N {{ incident.index }}</h4>
      </center>
      <hr />
      <div class="form-row">
        <base-input label="Intervenant">
          <el-select
            filterable
            clearable
            class="select-primary"
            v-model="incident.intervenant"
            name="intervenant"
          >
            <el-option
              v-for="intervenant in intervenants"
              class="select-primary"
              :value="intervenant.name"
              :label="(intervenant.name).toUpperCase()"
              :key="intervenant.name"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="form-row my-2">
        <base-input
          label="Commentaire"
          class="col-md-12"
          name="comment"
          v-model="incident.comment"
        >
        </base-input>
      </div>
      <center>
        <base-button
          v-if="!editing"
          type="info"
          @click="editIncident()"
          :disabled="editFormInvalid"
          >{{ $t("buttons.BtnModify").toUpperCase() }}
        </base-button>
        <base-button
          v-else
          loading
          round
          type="info"
        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
      </center>
    </card>
  </Modal_selfEvent>
</template>

<script>

import Modal_selfEvent from 'src/components/Modal_selfEvent'
import { API_URL } from "../API"
import { Select, Option } from "element-ui"

export default {
  components: {
    Modal_selfEvent,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    incident: {
      Type: Object,
      required: true,
      default: () => {}
    },
    intervenants: {
      Type: Array,
      required: true,
      default: () => []
    },
    modalClasses: {
      Type: [String, Object],
      required: false
    }
  },
  data() {
    return {
      isActive: true,
      editing: false,
      commentInitialValue: "",
      intervenantInitialValue: ""
    }
  },
  mounted() {
    this.commentInitialValue = this.incident.comment
    this.intervenantInitialValue = this.incident.intervenant
  },
  watch: {
    isActive(newValue, oldValue) {
      if( ! newValue) {
        this.closeModal()
      }
    }
  },
  computed: {
    editFormInvalid() {
      return this.incident.comment == this.commentInitialValue &&
      this.incident.intervenant == this.intervenantInitialValue
    }
  },
  methods: {
    async editIncident() {

      if(this.editFormInvalid) return

      const apiUrl = `${API_URL}/scenarios/${this.incident.scenario}/incidents/${this.incident.id}`
      const apiToken = localStorage.getItem('token_jwt')

      const comment = this.incident.comment ? this.incident.comment.trim() : ""
      const intervenant = this.incident.intervenant
      const date = this.incident.executionDate

      this.editing = true

      this.axios.put(
        apiUrl,
        {
          "date": date,
          "comment": comment,
          "intervenant": intervenant
        },
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`
          }
        }
      )
        .then(response => {
          setTimeout(() => {
            this.editing = false
            this.$emit('edited')
            this.$notify({type: 'success', message: response.data.message })
          },400)
        })
        .catch(error => {
          let errorMessage = ""
          try {
            errorMessage = error.response.data.message
          } catch (error) {
            errorMessage = "Une erreur est survenue...réessayer plus tard"
          }
          this.editing = false
          this.closeModal()
          this.$notify({type: 'warning', message: errorMessage })
        })

    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style>
  .edit-incident-modal {
    overflow-y: auto !important;
  }

  .edit-incident-modal .content h4 {
    margin-bottom: 5px;
  }

  .edit-incident-modal.show .modal-dialog {
    transform: none;
  }

  .edit-incident-modal .modal-dialog {
    max-width: 730px;
  }

  .label-break-all {
    word-break: break-all;
  }
</style>
