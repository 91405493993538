<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">STATISTIQUES GLOBALES</h2>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <base-input>
                      <el-select class="select-primary"
                          placeholder="PÉRIODE"
                          v-model="PeriodeSelects.Period">
                          <el-option 
                          v-for="option in PeriodeSelects.ListPeriod"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label.toUpperCase()"
                          :key="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-3">
                    <base-input :error="getError('date de début')">
                      <el-date-picker 
                        v-model="StartDate" 
                        type="date" 
                        value-format="yyyy/MM/dd" 
                        placeholder="DATE DE DÉBUT"
                        :picker-options="pickerOptions"
                        name="date de début"
                        v-validate="'required'"
                      ></el-date-picker>
                    </base-input>
                  </div>
                  <div class="col-md-3">
                    <base-input>
                      <el-date-picker 
                        v-model="EndDate" 
                        type="date" 
                        value-format="yyyy/MM/dd" 
                        placeholder="DATE DE FIN"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </base-input>
                  </div>
                  <base-input class="col-md-3">
                    <el-select filterable multiple collapse-tags class="select-primary"
                      clearable
                      placeholder="SUPPORT"
                      v-model="Support"
                      >
                      <el-option 
                        v-for="option in liste_supports"
                        class="select-primary"
                        :value="option.id_bases"
                        :label="option.nom_support.toUpperCase()"
                        :key="option.id_bases">
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input class="col-md-3">
                    <el-select filterable multiple collapse-tags class="select-primary"
                      clearable
                      placeholder="CAMPAGNES"
                      v-model="Campagne"
                      >
                      <el-option 
                        v-for="option in liste_campagnes"
                        class="select-primary"
                        :value="option.id_programme"
                        :label="option.Titre.toUpperCase()"
                        :key="option.id_programme">
                      </el-option>
                    </el-select>
                  </base-input>
                  <!-- <div class="col-md-8">
                    <button
                      class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                      @click="send()"
                      v-if="Flag"
                    >RÉINITIALISER</button>
                    <base-button v-else loading type="primary" class="mb-2 pull-right"></base-button>
                  </div> -->
                  <div class="col-md-9 float-right">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="$apollo.queries.statsGlobales_Editeur.loading" 
                        loading 
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button 
                        v-else 
                        class="btn btn-round btn-just-icon btn-primary" 
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" v-if='hideChart'>
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-info btn-simple"
                    :class="{ active: ChartStatGlobal.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="ChartStatGlobal.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        :content="option.name"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i :class="option.icon"></i>
                      </el-tooltip>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2">
            <line-chart
              v-if='hideChart'
              :labels="ChartStatGlobal.labels"
              :chart-data="ChartStatGlobal.chartData"
              :extra-options="ChartStatGlobal.extraOptions"
              :height="200"
            >
            </line-chart>
            <!-- <center> 
              <base-button v-if='!hideChart' loading type="primary">Chargement...</base-button>
            </center> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-sm-6">
            <button class="btn btn-info" @click="ExportTable()">
              <i class="tim-icons icon-cloud-download-93"></i> EXPORT
            </button>
          </div>
          <div class="p-2" data="black">
            <el-table :data="AllstatsGlobales" ref="elTable_stats" show-summary :summary-method="getSummaries">
              <el-table-column
                label="Jour"
                prop="jour"
                min-width="235"
                show-overflow-tooltip
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="Details(scope.row)">
                    {{ scope.row.jour.toUpperCase() }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                label="Impressions"
                prop="impressions"
                min-width="150"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.impressions) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Clics"
                prop="clics"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.clics) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Leads"
                prop="leads"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="Details(scope.row)">
                    <u>
                    {{ formatThounsends(scope.row.leads) }}
                    </u>
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                label="Ventes"
                prop="ventes"
                min-width="105"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ventes) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="CA"
                prop="ca"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true, 2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="CTR"
                prop="CTR"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.CTR, true, 2) + ' %' }}
                </template>
              </el-table-column>
              <el-table-column
                label="ECPC"
                prop="ECPC"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ECPC, true, 2) + ' €' }}
                </template>
              </el-table-column>
            </el-table>
            <center>
              <base-button v-if='$apollo.queries.statsGlobales_Editeur.loading' loading type="primary"></base-button>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import { Table, TableColumn } from 'element-ui';
import LineChart from 'src/components/Charts/LineChart'
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'
import { elTableExport } from "el-table-export";
import moment from 'moment';

let CurentDate = new Date()
let limitdate = new Date(2020, 0, 1)

let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.info,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.info,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: '#2380f7',
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

let bigChartLabels = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']

let bigChartData = [
  [],
  []
]

export default {
  apollo: {
    liste_campagnes: {
      query: gql`
        query {
          programmes_objectifs{
            id_programme
            Titre
          },
        }
      `,
      update: data => data.programmes_objectifs
    },
    list_programme_detail: {
      query: gql`query {
        list_programme_detail(flag:"details"){
          id_programme
          Titre
          repertoire
          provenance
          idcpart
        }
      }`,
      update: data => data.list_programme_detail
    },
    liste_supports: {
      query: gql`
        query($id_client: ID) {
          liste_supports(id_client: $id_client, orderBy: [
              {
                field: "id_support"
                order: DESC
              }
          ]) {
            id_support
            id_bases
            nom_support
          }
        }
      `,
      update: data => data.liste_supports,
      result({ data, loader, networkStatus }) {
      },
      variables() {
        return {
          id_client: JSON.parse(localStorage.getItem('utilisateur')).id
        };
      }
    },
    statsGlobales_Editeur: {
      query : gql`query ($day: Int,$month: String!,$year: String!,$id_client: Int,$dateEnd: String, $bases: [Int], $campagnes: [Int]) {
        statsGlobales_Editeur (
          day: $day
          month: $month
          year: $year
          id_client: $id_client
          droit: "af"
          dateEnd: $dateEnd
          bases: $bases
          campagnes: $campagnes
        )
        {
          flag
          returnDay
          jour
          impressions
          clics
          leads
          ventes
          ECPC
          CTR
          ca
        }        
      }`,
      fetchPolicy: 'network-only',
      skip(){
        return this.SkipQuery 
      },
      variables() {
        return {
          day: null,
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + "",
          id_client: this.id_client,
          dateEnd: (this.EndDate) ? this.EndDate.replace(new RegExp('/', 'g'), '-') : "",
          bases: this.Support,
          campagnes: this.Campagne
        }
      },
      result({ data, loader, networkStatus }) {
        this.returnDay = data.statsGlobales_Editeur[0].returnDay
        this.Flag = data.statsGlobales_Editeur[0].flag
        this.AllstatsGlobales = [...this.AllstatsGlobales,...data.statsGlobales_Editeur]  
        this.DateStart.add(5, 'days');   
      },
    }
  },
  computed: {
    bigLineChartCategories() {
      return [
        { name: 'CA', icon: 'tim-icons icon-coins' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    LineChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      DateStart: null,
      Support: [],
      Campagne: [],
      Campagne_withDuplicate: [],
      mounth: [ 
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() || time.getTime() < limitdate.getTime();
        }
      },
      PeriodeSelects: {
        Period: '',
        ListPeriod: [
          { value: "YESTERDAY" , label: "Hier"},
          { value: "CURRENTDAY" , label: "Aujourd'hui"},	
          { value: "CURRENTMONTH" , label: "Mois en cours"},
          { value: "LASTMONTH" , label: "Mois précédent"}
        ]
      },
      EndDate: null,
      StartDate: null,
      AllstatsGlobales: [],
      id_client: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil,
      returnDay: null,
      SkipQuery: true,
      Flag: false,
      hideChart: false,
      SUMimpressions: 0,
      SUMclics: 0,
      SUMlead: 0,
      SUMventes: 0,
      SUMECPC: 0,
      SUMca: 0,
      ChartStatGlobal: {
        activeIndex: 0,
        chartData: {
          labels: bigChartLabels,
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: bigChartData[0]
            }
          ],
        },
        extraOptions: {
          ...chartConfigs.lineChartOptionsBlue,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartData[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 150,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        }
      },
    };
  },
  methods: {
    ExportTable() {
      let eteInstance = new elTableExport(this.$refs.elTable_stats, {
        fileName: "export",
        type: "xls",
        withBOM: false,
      });
      eteInstance.export();
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value : value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      // this.$refs.bigChart.updateGradients(chartData);
      this.ChartStatGlobal.chartData = chartData;
      this.ChartStatGlobal.activeIndex = index;
    },
    async send() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      if (this.EndDate && this.StartDate) {
        var Difference_In_Time = new Date(this.EndDate).getTime() - new Date(this.StartDate).getTime();
        if (Difference_In_Time / (1000 * 3600 * 24) < 0) {
          return
        }

      }

      if (this.EndDate && limitdate.getTime() > new Date(this.EndDate).getTime()) {
        return
      }

      if (this.StartDate && limitdate.getTime() > new Date(this.StartDate).getTime()) {
        return
      }

      let array = []
      this.Campagne_withDuplicate = this.Campagne
      this.Campagne.forEach(element => {
        array = [...array,...this.list_programme_detail.filter(x => x.idcpart === parseInt(element))]
      });
      this.Campagne_withDuplicate = [...this.Campagne_withDuplicate,...array.map(a => a.id_programme)];
      this.hideChart = false
      this.AllstatsGlobales = []

      this.DateStart = moment(this.StartDate)
      this.returnDay = parseInt(this.DateStart.format('D'))
      this.SkipQuery = false
      this.Flag = false
      while(!this.Flag){
        await this.$apollo.queries.statsGlobales_Editeur.refetch({
          day: this.returnDay,
          month: this.DateStart.format('M'),
          year: this.DateStart.format('YYYY'),
          id_client: this.id_client,
          droit: 'af',
          dateEnd: (this.EndDate) ? this.EndDate.replace(new RegExp('/', 'g'), '-') : "",
          bases: this.Support,
          campagnes: this.Campagne_withDuplicate
        })
      }
      let label = []
      let datalead = []
      let dataCA = []
      let SUMimpressions = 0
      let SUMclics = 0
      let SUMlead = 0
      let SUMventes = 0
      let SUMECPC = 0
      let SUMca = 0
      for (let index = 0; index < this.AllstatsGlobales.length; index++) {
        label.push(this.AllstatsGlobales[index].jour.split(" ")[1] + "/" + this.AllstatsGlobales[index].jour.split(" ")[2] )
        datalead.push(this.AllstatsGlobales[index].leads)
        dataCA.push(this.AllstatsGlobales[index].ca)
        SUMimpressions += this.AllstatsGlobales[index].impressions
        SUMlead += this.AllstatsGlobales[index].leads
        SUMventes += this.AllstatsGlobales[index].ventes
        SUMECPC += this.AllstatsGlobales[index].ECPC
        SUMca += this.AllstatsGlobales[index].ca
        SUMclics += this.AllstatsGlobales[index].clics
      }
      this.SUMimpressions = SUMimpressions
      this.SUMclics = SUMclics
      this.SUMlead = SUMlead
      this.SUMventes = SUMventes
      this.SUMECPC = SUMECPC
      this.SUMca = SUMca
      bigChartData[0] = dataCA 
      bigChartData[1] = datalead
      bigChartLabels = label
      this.initBigChart(0)
      this.hideChart = true
      this.SkipQuery = true
    },
    Details(row) {
      let Details = {}
      Details.jour = row.jour.split(' ')[3] + '/' + (this.mounth.indexOf(row.jour.split(' ')[2]) + 1) + '/' + row.jour.split(' ')[1]
      Details.droit = 'af'
      Details.id_client = JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil
      Details.id_programme = null
      Details.programmes = this.Campagne_withDuplicate
      Details.bases = this.Support
      Details.title_programme = null
      Details.manager = null
      localStorage.setItem("Details", JSON.stringify(Details))
      let routeData = this.$router.resolve({name: 'stats Detail Leads day for Editeur'});
      window.open(routeData.href, '_blank');   
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 1) {
          sums[index] = this.formatThounsends(this.SUMimpressions)
          return;
        }
        else if( index === 2) {
          sums[index] = this.formatThounsends(this.SUMclics)
          return;
        }
        else if( index === 3) {
          sums[index] = this.formatThounsends(this.SUMlead)
          return;
        }
        else if( index === 4) {
          sums[index] = this.formatThounsends(this.SUMventes) + " €"
          return;
        }
        else if( index === 7) {
          sums[index] = this.formatThounsends(this.SUMECPC, true, 2) + ' €'
          return;
        }
        else if( index === 6) {
          if(this.SUMclics != 0)
            sums[index] = this.formatThounsends((this.SUMlead/this.SUMclics)*100, true, 2) + ' %'
          else
            sums[index] = 0 + ' %'
          return;
        }
        else if( index === 5) {
          sums[index] = this.formatThounsends(this.SUMca, true,2) + ' €'
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
  },
  watch: {
    'PeriodeSelects.Period'(value){
      if(value == "YESTERDAY") {
        this.StartDate = CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ (CurentDate.getDate()-1)
        this.EndDate = ""
      } else if(value == "CURRENTDAY") {
        this.StartDate = CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ CurentDate.getDate()
        this.EndDate = ""
      } else if(value == "CURRENTMONTH") {
        this.StartDate = CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ 1
        this.EndDate   = CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ CurentDate.getDate()
      } else if(value == "LASTMONTH") {
        if (CurentDate.getMonth()+1 == 1) {
          this.StartDate = (CurentDate.getFullYear() - 1) +"/"+ 12 +"/"+ 1
          this.EndDate   = (CurentDate.getFullYear() - 1) +"/"+ 12 +"/"+ 31
        }
        else {
          this.StartDate = CurentDate.getFullYear() +"/"+ CurentDate.getMonth() +"/"+ 1
          this.EndDate   = CurentDate.getFullYear() +"/"+ CurentDate.getMonth() +"/"+ new Date(CurentDate.getFullYear(), CurentDate.getMonth() , 0).getDate()
        }
      }
    },
  }
};
</script>