<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <!-- <h3>{{ $t("labels.TitlePageShoot").toUpperCase() }}</h3> -->
        <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
          <base-button
            class="btn btn-round btn-just-icon btn-info"
            @click.native="toEndPage"
          >{{ $t("buttons.BtnShoot").toUpperCase() }}</base-button>
        </div>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <base-input label="DATE DÉBUT" class="col-md-6">
                    <el-date-picker 
                      v-model="StartDate" 
                      type="date" 
                      value-format="yyyy-MM-dd" 
                      placeholder="AAAA-MM-JJ"
                      :clearable="false"
                    ></el-date-picker>
                  </base-input>
                  <base-input label="DATE FIN" class="col-md-6">
                    <el-date-picker 
                      v-model="EndDate" 
                      type="date" 
                      value-format="yyyy-MM-dd" 
                      placeholder="AAAA-MM-JJ"
                      :clearable="false"
                    ></el-date-picker>
                  </base-input>
                </div>
              </div>
              <div class="col-md-6">
                <base-button 
                  class="btn btn-just-icon btn-default float-right d-block row mt-4" 
                  @click="search"
                  :disabled="$apollo.queries.listshoot.loading"
                  v-if="!$apollo.queries.listshoot.loading" 
                >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                <base-button 
                  v-else
                  loading 
                  class="btn btn-just-icon btn-default float-right d-block row mt-4"
                >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
              </div>
            </div>
          </div>
        </card>
        <card>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="col-xl-1 col-sm-6">
              <base-input>
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-xl-11 col-sm-6">
              <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
            </div>

            <!-- <base-input>
              <el-input
                type="search"
                class="mb-3 search-input"
                clearable
                prefix-icon="el-icon-search"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </base-input> -->
            <!-- <div class="col-xl-2 col-sm-6">
              <button class="btn btn-info" @click="ExportTable()">
                <i class="tim-icons icon-cloud-download-93"></i> EXPORT
              </button>
            </div> -->
          </div>
          <el-table :data="queriedData" v-loading="$apollo.queries.listshoot.loading">
            <el-table-column
              prop="titre"
              label="Programme"
              min-width="130"
              sortable
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="nom_campagne"
              label="Campagne"
              min-width="300"
              sortable
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="date_insert"
              label="Date"
              min-width="120"
              sortable
              show-overflow-tooltip
            >
              <template scope="props">
                {{ props.row.jour+"-"+props.row.mois+"-"+props.row.annee }}
              </template>
            </el-table-column>
            <el-table-column
              prop="volume"
              label="volume"
              min-width="139"
              sortable
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="Actions" min-width="130">
              <div slot-scope="props">
                <base-button
                  :disabled="props.row.etat == 'SHOOTENVOYE'"
                  @click.native="handleEdit(props.row)"
                  class="edit btn-link"
                  type="warning"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>

        </card>
        <h3>AJOUT / SAISIE DES SHOOTS</h3>
        <card>
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <base-input class="col-md-3" label="CAMPAGNE" :error="getError('campagne')">
                  <el-select class="select-primary" filterable clearable
                    name="campagne"
                    v-validate="'required'"
                    placeholder="CHOISISSEZ"
                    v-model="CampagneShootSelects.Campagne">
                    <el-option 
                      v-for="option in CampagneShootSelects.ListCampagne"
                      class="select-primary"
                      :value="option.id_campagne"
                      :label="option.nom_campagne.toUpperCase()"
                      :key="option.id_campagne">
                    </el-option>
                  </el-select>
                </base-input>
                <base-input class="col-md-3" label="DATE" :error="getError('date')">
                  <el-date-picker 
                    name="date"
                    v-validate="'required'"
                    v-model="DateShoot" 
                    type="date" 
                    placeholder="AAAA-MM-JJ" 
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
                </base-input>
                <base-input 
                  class="col-md-2"
                  :error="getError('volume')" 
                  name="volume" 
                  v-validate="'required'" 
                  label="VOLUME" 
                  type="number" 
                  v-model="VolumeShoot">
                </base-input>
                <base-input label="ETAT" :error="getError('etat')" class="col-md-2">
                  <el-select class="select-primary"
                    name="etat" 
                    v-validate="'required'" 
                    placeholder="CHOISISSEZ"
                    v-model="EtatShootSelects.EtatShoot">
                    <el-option 
                      v-for="option in EtatShootSelects.ListEtatShoot"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
                <base-input label="SOUS-ÉTAT" class="col-md-2">
                  <el-select class="select-primary"
                    name="sous-état" 
                    placeholder="CHOISISSEZ"
                    v-model="SousEtatShootSelects.SousEtatShoot">
                    <el-option 
                      v-for="option in SousEtatShootSelects.ListSousEtatShoot"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row mt-4">
                <div class="form-group col-auto mx-auto">
                  <button class="btn btn-just-icon btn-info float-left mr-2 d-block" v-on:click="updateOrCreateShoot()">{{ $t("buttons.BtnValidate").toUpperCase() }}</button>
                  <button class="btn btn-just-icon btn-default d-block" @click="reset">{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <div id="ENDPAGE"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Select, Option ,DatePicker} from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { BasePagination } from 'src/components';
  import gql from 'graphql-tag'

  let today = new Date();

  export default {
    apollo: {
      listshoot: {
        query : gql` query (
            $StartDate: String,
            $EndDate: String,
            $editeur: Int!
          ) 
        {
          liste_Shoots_Editeur
            (
              startDate: $StartDate
              endDate: $EndDate
              editeur: $editeur
            )
          {
            email 
            id_client
            nom_campagne
            id_type_compagne 
            titre
            id_programme
            type_campagne
            id_shoot
            id_campagne
            jour
            mois
            annee
            etat
            sous_etat
            date_ajout
            volume
          }
        }`,
        skip() {
          return this.SkipQuery
        },
        fetchPolicy: 'network-only',
        update: data => data.liste_Shoots_Editeur,
        result({ data, loader, networkStatus }) {
          this.tableData = data.liste_Shoots_Editeur
          this.SkipQuery = true
        },
        variables() {
          return {
            StartDate: this.StartDate,
            EndDate: this.EndDate,
            editeur: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil
          }
        }
      },
      campagnes: {
        query: gql`
          query(
            $id_client: Int!
            $id_programme: Int
            $nom_base: String
            $sub_id: Int
          ) {
            liste_campagnes(
              id_client: $id_client
              id_programme: $id_programme
              nom_base: $nom_base
              sub_id: $sub_id
            ) {
              nom_campagne
              id_campagne
              id_programme
            }
          }
        `,
        update: data => data.liste_campagnes,
        result({ data, loader, networkStatus }) {
          this.CampagneShootSelects.ListCampagne = data.liste_campagnes
        },
        variables() {
          return {
            id_client: JSON.parse(localStorage.getItem("utilisateur")).id_clien_myaffil,
            id_programme: null,
            nom_base: null,
            sub_id: null
          };
        }
      }
    },
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        StartDate: new Date(today.getFullYear(), today.getMonth() , 1),
        EndDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
        tableData: [],
        total: 0,
        SkipQuery: false,
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [25, 50, 300],
          total: 0
        },
        CampagneShootSelects: {
          Campagne: '',
          ListCampagne: []
        },
        DateShoot: '',
        VolumeShoot: '',
        EtatShootSelects: {
          EtatShoot: '',
          ListEtatShoot: [
            {value: 'ABAT', label:'ABAT'},
            {value: 'BATOK', label:'BAT OK'},
            {value: 'SHOOTENVOYE', label:'Shoot envoyé'}
          ]
        },
        SousEtatShootSelects: {
          SousEtatShoot: '',
          ListSousEtatShoot: [
            {value: 'VALIDE', label:'Valide'},
            {value: 'ANNULE', label:'Annulé'},
            {value: 'REPORTE', label:'Reporté'}
          ]
        },
        id_shoot: null
      }
    },
    computed: {
      queriedData() {
        let result = this.tableData;
        this.total = this.tableData.length
        return result.slice(this.from,this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      toEndPage() {
        let elmnt = document.getElementById('ENDPAGE');
        elmnt.scrollIntoView(false);
      },
      search() {
        this.SkipQuery = false
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async updateOrCreateShoot() {
        let Programme
        try {
          Programme = this.CampagneShootSelects.ListCampagne.find(x => x.id_campagne === this.CampagneShootSelects.Campagne).id_programme
        } catch (error) {
          return
        }
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

          this.$apollo.mutate({
        // Query
          mutation: gql`mutation (
            $id_shoot: Int
            $id_client: Int
            $id_programme: Int
            $id_campagne: Int
            $date: String
            $date_ajout: String
            $volume: Int
            $etat: String
            $sous_etat: String
          ) {
              updateOrCreate_Shoots(
                id_shoot: $id_shoot
                id_programme: $id_programme
                id_campagne: $id_campagne
                id_client: $id_client
                date: $date
                date_ajout: $date_ajout
                volume: $volume
                etat: $etat
                sous_etat: $sous_etat
              )
              {
                id_shoot
                id_client
                id_campagne
                date_ajout
                volume
                etat
                sous_etat
              }
          }`,
          variables: {
            id_shoot: this.id_shoot,
            id_programme: Programme,
            id_campagne: this.CampagneShootSelects.Campagne,
            id_client: JSON.parse(localStorage.getItem("utilisateur")).id_clien_myaffil,
            date: this.DateEditeShoot,
            date_ajout: null,
            volume: this.VolumeShoot,
            etat: this.EtatShootSelects.EtatShoot,
            sous_etat: this.SousEtatShootSelects.SousEtatShoot
          },
        }).then((data) => {
          var message = ''
          if(this.id_shoot != null){
            message = 'Le shoot a été bien modifié.'
          }else{
            this.reset()
            message = 'Le shoot a été bien ajouté.'
          }
          this.SkipQuery = false
          this.$apollo.queries.listshoot.refetch({
            StartDate: this.StartDate,
            EndDate: this.EndDate,
            editeur: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil
          })
          this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message})
        }).catch((error) => {
          if(this.id_shoot != null){
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          }else{
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
          }
        })
      },
      reset() {
        this.id_shoot = null
        this.CampagneShootSelects.Campagne = ""
        this.DateShoot = ""
        this.VolumeShoot = ""
        this.EtatShootSelects.EtatShoot = ""
        this.SousEtatShootSelects.SousEtatShoot = ""
      },
      handleEdit(row) {
        this.toEndPage()
        this.id_shoot = row.id_shoot
        this.CampagneShootSelects.Campagne = "" + row.id_campagne
        this.DateShoot = row.annee+'-'+row.mois+'-'+row.jour
        this.VolumeShoot = row.volume
        this.EtatShootSelects.EtatShoot = row.etat
        this.SousEtatShootSelects.SousEtatShoot = row.sous_etat
      },
    },
  }
</script>