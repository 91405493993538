import Axios from 'axios'

// const API_URL = "http://localhost:8888"
const API_URL = "https://check.wee-do-it.net"

const doesAuthUserExist = () => {

  return new Promise((resolve, reject) => {

    const apiUrl = `${API_URL}/users/me`
    const apiToken = localStorage.getItem('token_jwt')

    Axios.get(
      apiUrl,
      {
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${apiToken}`,
        },
      }
    )
      .then(() => resolve(true))
      .catch(error => {

        const response = error.response

        //user not found
        if(response && response.status === 404) return resolve(false)

        reject(error)
      })
  })
}

const addAuthUser = () => {

  return new Promise((resolve, reject) => {

    const apiUrl = `${API_URL}/users`
    const authUser = JSON.parse(localStorage.getItem('utilisateur'))

    Axios.post(
      apiUrl,
      {
        id: authUser.id,
        email: authUser.email,
        law: authUser.law,
        society: authUser.society
      },
      {
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${authUser.api_token}`,
        },
      }
    )
      .then(response => resolve(response.message))
      .catch(error => reject(error))
  })
}

const addAuthUserIfItDoesntExist = async() => {

  // if( ! await doesAuthUserExist()) {
  //   await addAuthUser()
  // }
}

const getAuthUserAccounts = () => {

  return new Promise((resolve, reject) => {

    const apiUrl = `${API_URL}/users`
    const apiToken = localStorage.getItem('token_jwt')

    Axios.get(
      apiUrl,
      {
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${apiToken}`,
        }
      }
    )
      .then(({ data }) => resolve(data.accounts))
      .catch(error => reject(error))
  })
}

const getAuthUserScenarios = () => {

  return new Promise((resolve, reject) => {

    const apiUrl = `${API_URL}/users/me/scenarios`
    const apiToken = localStorage.getItem('token_jwt')

    Axios.get(
      apiUrl,
      {
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${apiToken}`,
        }
      }
    )
      .then(({ data }) => resolve(data.scenarios))
      .catch(error => reject(error))
  })
}

export {
  API_URL,
  addAuthUserIfItDoesntExist,
  getAuthUserAccounts,
  getAuthUserScenarios
}
