<template>
  <div>
    <!-- events -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 text-left">
                <h2 class="card-title">{{ "événements".toUpperCase() }}</h2>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-2 col-sm-6">
                <base-input :error="getError($t('table.columns.StartDate'))">
                  <el-date-picker
                    v-model="StartDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :placeholder="$t('table.columns.StartDate').toUpperCase()"
                    :name="$t('table.columns.StartDate')"
                    v-validate="'required'"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </base-input>
              </div>
              <div class="col-md-2 col-sm-6">
                <base-input :error="getError($t('table.columns.EndDate'))">
                  <el-date-picker
                    v-model="EndDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :placeholder="$t('table.columns.EndDate').toUpperCase()"
                    :name="$t('table.columns.EndDate')"
                    v-validate="'required'"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </base-input>
              </div>
              <div class="col-md-2 col-sm-6">
                <base-input :error="getError('compte')">
                  <el-select
                    filterable
                    clearable
                    class="select-primary"
                    v-model="accounts.activeAccountId"
                    :disabled="Loading.accounts"
                    v-validate="'required'"
                    name="compte"
                  >
                    <el-option
                      v-for="account in accounts.list"
                      class="select-primary"
                      :value="account.id"
                      :label="(account.society).toUpperCase()"
                      :key="account.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-2 col-sm-6">
                <base-input :error="getError('scenario')">
                  <el-select
                    filterable
                    clearable
                    class="select-primary"
                    v-model="scenarios.activeScenario"
                    :disabled="Loading.scenarios"
                    v-validate="'required'"
                    name="scenario"
                  >
                    <el-option
                      class="select-primary"
                      value='all'
                      :label="'Tous les scénarios'.toUpperCase()"
                    >
                    </el-option>
                    <el-option
                      v-for="scenario in scenariosListFiltered"
                      class="select-primary"
                      :value="scenario.name"
                      :label="(scenario.description).toUpperCase()"
                      :key="scenario.name"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <button
                    @click="reset"
                    class="btn btn-round btn-just-icon btn-primary mt-0 pull-right"
                  >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                  <button
                    v-if="!searchLoading"
                    @click="Search()"
                    class="btn btn-round btn-just-icon btn-primary mt-0"
                  >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                  <base-button
                    v-else
                    loading
                    round
                    type="primary"
                    class="btn btn-round btn-just-icon btn-primary mt-0"
                  >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="charts-container p-2 mt-3"
            :class="{'charts-disabled': disableCharts}"
          >
            <div class="charts" v-if="showChart">
              <div class="chart-header row">
                <div class="col-md-12 col-sm-2">
                <div class="btn-group btn-group-toggle float-left ml-2" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in LineChartTypes"
                    :key="option.name"
                    class="btn btn-sm btn-info btn-simple"
                    :class="{ active: DailyLineChart.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initLineChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="DailyLineChart.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        :content="option.name"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i :class="option.icon"></i>
                      </el-tooltip>
                    </span>
                  </label>
                </div>
              </div>
              </div>
              <div class="chart-area">
                <line-chart
                  :labels="DailyLineChart.labels"
                  :chart-data="DailyLineChart.chartData"
                  :extra-options="DailyLineChart.extraOptions"
                  :height="200"
                >
                </line-chart>
              </div>
            </div>
            <div v-else style="height:250px;">
              <base-button
                class="centered-loading"
                loading
                type="default"
              >
            </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end events -->

    <!-- incidents -->
    <div class="row mt-4">
      <div class="col-lg-12">
        <card>
          <div>
            <div class="col-md-4 pl-0 mb-4">
              <h2 class="card-title">{{ "Historique des incidents".toUpperCase() }}</h2>
            </div>
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <el-table :data="incidentsList" v-loading="Loading.incidents" row-class-name="incidents-table-rows" class="customtable_cmd">
              <el-table-column
                prop="id"
                label="Id"
                min-width="55"
                sortable
              >
                <template scope="scope">
                  {{ getIncidentIndex(scope.row.id, scope.row.executionDate) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="description"
                label="scénario"
                min-width="135"
                sortable
              >
                <template scope="scope">
                  <template v-if="scope.row.scenarioDetails.description.length <= 20">
                    {{ scope.row.scenarioDetails.description }}
                  </template>
                  <template v-else>
                    <el-tooltip
                      :content="scope.row.scenarioDetails.description"
                      :open-delay="300"
                      effect="light"
                      placement="top"
                      popper-class="scenario-tooltip"
                    >
                      <label>
                        {{ scope.row.scenarioDetails.description.slice(0,17) }}...
                      </label>
                    </el-tooltip>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="errorMessage"
                label="incident"
                class-name="events-incident"
                min-width="295"
                sortable
              >
                <template scope="scope">
                  <template v-if="scope.row.errorMessage.length <= 53">
                    {{ scope.row.errorMessage }}
                  </template>
                  <template v-else>
                    <el-tooltip
                      :content="scope.row.errorMessage"
                      :open-delay="300"
                      effect="light"
                      placement="top"
                      popper-class="incident-tooltip"
                    >
                      <label>
                        {{ scope.row.errorMessage.slice(0,50) }}...
                      </label>
                    </el-tooltip>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="comment"
                label="Commentaire"
                class-name="events-comment"
                min-width="295"
                sortable
              >
                <template scope="scope">
                  <template v-if="!scope.row.comment"> - </template>
                  <template v-else-if="scope.row.comment.length <= 53">
                    {{ scope.row.comment }}
                  </template>
                  <template v-else>
                    <el-tooltip
                      :content="scope.row.comment"
                      :open-delay="300"
                      effect="light"
                      placement="top"
                      popper-class="comment-tooltip"
                    >
                      <label>
                        {{ scope.row.comment.slice(0,50) }}...
                      </label>
                    </el-tooltip>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="executionDate"
                label="date de production"
                min-width="130"
                sortable
              >
                <template scope="scope">
                  {{ formatDate(scope.row.executionDate) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="intervenant"
                label="Intervenant"
                min-width="140"
                sortable
              >
                <template scope="scope">
                  <template v-if="!scope.row.intervenant"> - </template>
                  <template v-else-if="scope.row.intervenant.length <= 35">
                    {{ scope.row.intervenant }}
                  </template>
                  <template v-else>
                    <el-tooltip
                      :content="scope.row.intervenant"
                      :open-delay="300"
                      effect="light"
                      placement="top"
                      popper-class="intervenant-tooltip"
                    >
                      <label>
                        {{ scope.row.intervenant.slice(0,32) }}...
                      </label>
                    </el-tooltip>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop label="Action"
                min-width="95">
                <template scope="scope">
                  <el-tooltip
                    :content="$t('buttons.BtnView').toUpperCase()"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="viewIncident(scope.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-tv-2"></i>
                    </base-button>
                  </el-tooltip>
                   <el-tooltip
                    :content="$t('buttons.BtnModify').toUpperCase()"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="editIncident(scope.row)"
                      class="edit btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p
                class="card-category"
                v-show="! emptyIncidentsList"
              >{{ $t('labels.Datatable.Showing') }} {{ fromPage + 1 }} {{ $t('labels.Datatable.to') }} {{ toPage }} {{ $t('labels.Datatable.of') }} {{ incidentsTotal }} {{ $t('labels.Datatable.entries') }}</p>
            </div>
            <base-pagination
              v-show="! emptyIncidentsList"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="incidentsTotal"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
    <!-- end incidents -->

    <!-- incidents modal -->
    <test-details-modal
      v-if="showedIncident"
      title="Détails de l'incident"
      :imageUrl="showedIncident.screenshotURL"
      @close="() => showedIncident = null"
    >
      <template>
        <div class="form-row">
          <label class="col-md-12">
            <b> Incident : </b>{{ showedIncident.errorMessage }}
          </label>
        </div>
        <div class="form-row">
          <label class="col-md-12">
            <b> Date de production : </b>{{ formatDate(showedIncident.executionDate) }}
          </label>
        </div>
        <div class="form-row">
          <label class="col-md-12">
            <b> Étape : </b>{{ showedIncident.step }}
          </label>
        </div>
      </template>
    </test-details-modal>
    <!-- end incident modal -->

    <!-- edit incident modal -->
    <edit-incident-modal
      v-if="incidentToEdit"
      :incident="incidentToEdit"
      :intervenants="intervenants"
      @edited="onIncidentEdit()"
      @close="resetIncidentToEdit()"
    >
    </edit-incident-modal>
    <!-- end edit incident modal -->

  </div>
</template>

<script>
import { Select, Option, DatePicker, Table, TableColumn } from "element-ui"
import LineChart from "@/components/Charts/LineChart"
import * as chartConfigs from "@/components/Charts/config"
import config from "@/config"
import { BasePagination } from "src/components";

import moment from 'moment'
moment.locale(localStorage.getItem('lang') == "en" ? "en" : "fr")

import { API_URL, addAuthUserIfItDoesntExist, getAuthUserScenarios, getAuthUserAccounts }  from 'src/pages/GlobalWeedoCheck/API'
import TestDetailsModal from 'src/pages/GlobalWeedoCheck/modals/TestDetailsModal'
import EditIncidentModal from 'src/pages/GlobalWeedoCheck/modals/EditIncidentModal'

const TODAY = new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate()
const START_OF_CURRENT_MONTH = new moment().startOf('months').format('YYYY-MM-DD')

const END_OF_CURRENT_MONTH = new moment().endOf('months').format('YYYY-MM-DD')
const DEFAULT_SCENARIO = "all"


const dailyLaunchesChartDatasetsOptions = {
  fill: true,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: '#2380f7',
  pointBorderWidth: 20,
  pointHoverRadius: 2,
  pointHoverBorderWidth: 15,
  pointRadius: 4
}

const dailyLaunchesChartSuccessDatasetsOptions = {
  borderColor: config.colors.primary,
  pointBackgroundColor: config.colors.primary,
  ...dailyLaunchesChartDatasetsOptions
}

const dailyLaunchesChartFailureDatasetsOptions = {
  borderColor: config.colors.danger,
  pointBackgroundColor: config.colors.danger,
 ...dailyLaunchesChartDatasetsOptions
}

let DailyCharts = [
  {
    chartData: {
      labels: [],
      datasets: [
        {
          label: 'Nombre de lancement réussis',
          ...dailyLaunchesChartSuccessDatasetsOptions,
          data: []
        },
        {
          label: 'Nombre de lancement échoués',
          ...dailyLaunchesChartFailureDatasetsOptions,
          data: []
        }
      ]
    }
  },
  {
    chartData: {
      labels: [],
      datasets: [
        {
          label: "Taux de réussite",
          ...dailyLaunchesChartSuccessDatasetsOptions,
          data: []
        },
        {
          label: "Taux d'échec",
          ...dailyLaunchesChartFailureDatasetsOptions,
          data: []
        }
      ]
    }
  }
]

let ChartOptions = {
    ...chartConfigs.lineChartOptionsBlue,
    legend: {
      display: true,
      labels: {
        padding: 20
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItems, data) => {
          const label = data.datasets[tooltipItems.datasetIndex].label
          const value = tooltipItems.yLabel
          const result = `${label}: ${value}`

          return label.includes('Taux') ? `${result}%` : result
        }
      }
    },
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.0)',
            zeroLineColor: 'transparent'
          },
          ticks: {
            beginAtZero:true,
            min: 0,
            max: SCALE_Y_MAX_DEFAULT,
            callback: function(value) {if (value % 1 === 0) {return value}},
            fontColor: '#9e9e9e'
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.1)',
            zeroLineColor: 'transparent'
          },
          ticks: {
            padding: 20,
            fontColor: '#9e9e9e'
          }
        }
      ]
    }
}

const SCALE_Y_MAX_DEFAULT = 100

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LineChart,
    BasePagination,
    TestDetailsModal,
    EditIncidentModal
  },
  data() {
    return {
      monthsArray: this.$t('labels.Mounths'),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      StartDate: START_OF_CURRENT_MONTH,
      EndDate: TODAY,
      DailyLineChart: {
        activeIndex: 0,
        chartData: DailyCharts[0].chartData,
        extraOptions: ChartOptions
      },
      Loading: {
        accounts: true,
        scenarios: true,
        stats: true,
        charts: true,
        incidents: true
      },
      disableCharts: false,
      scenarios: {
        activeScenario: DEFAULT_SCENARIO,
        list: []
      },
      accounts: {
        activeAccountId: null,
        list: []
      },
      incidents: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      incidentsTotal: 0,
      showedIncident: null,
      incidentToEdit: null,
      intervenants: []
    }
  },
  async created() {

    try {

      await addAuthUserIfItDoesntExist()

      await getAuthUserAccounts()
        .then(accounts => {
          this.accounts.list = accounts
          this.resetActiveAccountId()
          this.disableAccountsLoading()

          if(this.accounts.activeAccountId) {
            getAuthUserScenarios()
              .then(scenarios => {
                this.scenarios.list = scenarios
                this.disableScenariosLoading()
              })
              .catch(error => this.disableScenariosLoading())

            this.Search()
          } else {
            throw 'Auth user has no account'
          }
        })

    }
    catch (error) {
      setTimeout(() => {
        this.disableAllLoading()
        this.makeChartsDisabled()
      },1000)
    }
  },
  watch: {
    'accounts.activeAccountId'() {
      this.scenarios.activeScenario = DEFAULT_SCENARIO //reset to default
    }
  },
  computed: {
    scenariosListFiltered() {
      return this.scenarios.list.filter(scenario => scenario.userId == this.accounts.activeAccountId)
    },
    LineChartTypes() {
      return [
        { name: "Nombre de lancement", icon: 'tim-icons icon-coins' },
        { name: "Taux de réussite", icon: 'tim-icons icon-badge' }
      ]
    },
    showChart() {
      return !this.Loading.stats && !this.Loading.charts
    },
    searchLoading() {
      return this.Loading.stats || this.Loading.incidents
    },
    incidentsList() {
      if(this.emptyIncidentsList) return this.incidents
      return this.incidents.slice(this.fromPage, this.toPage)
    },
    emptyIncidentsList() {
      return this.incidents.length === 0
    },
    fromPage() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    toPage() {
      let highBound = this.fromPage + this.pagination.perPage
      if (this.incidentsTotal < highBound) {
        highBound = this.incidentsTotal
      }
      return highBound
    }
  },
  methods: {
    getIncidentIndex(incidentId, incidentDate) {

      let index = this.incidents.findIndex(incident => incident.id == incidentId && incidentDate == incident.executionDate)

      return ++index
    },
    initLineChart(index) {

      this.activateChartsLoading()

      this.DailyLineChart.chartData = DailyCharts[index].chartData
      this.DailyLineChart.activeIndex = index

      //this.set_scale_y_max_value()

      this.$nextTick(() => {
        this.disableChartsLoading()
      })
    },
    set_scale_y_max_value() {

      const max1 = Math.max(...this.DailyLineChart.chartData.datasets[0].data)
      const max2 = Math.max(...this.DailyLineChart.chartData.datasets[1].data)

      let scale_y_max_value = Math.max(max1, max2)

      if(scale_y_max_value > 0) {

        const doesNumberEndWithZero = (num) => num.toString().split("")[num.toString().length - 1] == '0'

        while( ! doesNumberEndWithZero(scale_y_max_value)) {
          scale_y_max_value++
        }

      } else {
        scale_y_max_value = SCALE_Y_MAX_DEFAULT
      }

      this.DailyLineChart.extraOptions.scales.yAxes[0].ticks.max = scale_y_max_value
    },
    async Search() {

      let validate

      await this.$validator.validateAll().then(isValid => validate = isValid)

      if ( ! validate) return

      this.getStats()

      this.getIncidentsList()

      this.getIntervenantsList()
    },
    getStats() {

      this.Loading.stats = true

      const apiUrl = `${API_URL}/users/${this.accounts.activeAccountId}/stats`
      const apiToken = localStorage.getItem('token_jwt')

      this.axios.post(
        apiUrl,
        {
          "startDate": this.StartDate,
          "endDate": this.EndDate,
          "scenario": this.scenarios.activeScenario
        },
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`
          }
        }
      )
        .then(({ data }) => {

          this.prepareDailyLaunchesData(data.stats.dailyLaunchesStats)
          this.prepareDailyLaunchesRatesData(data.stats.dailySuccessAndFailureRates)

          this.disableStatsLoading()
          this.disableCharts = false

          this.initLineChart(0)
        })
        .catch(error => {
          this.disableStatsLoading()
          this.disableChartsLoading()
          this.makeChartsDisabled()
        })
    },
    getIncidentsList() {

      this.incidents = []
      this.Loading.incidents = true

      const apiUrl = `${API_URL}/users/${this.accounts.activeAccountId}/tests`
      const apiToken = localStorage.getItem('token_jwt')

      this.axios.post(
        apiUrl,
        {
          "startDate": this.StartDate,
          "endDate": this.EndDate,
          "scenario": this.scenarios.activeScenario,
          "success": false
        },
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`
          }
        }
      )
        .then(({ data }) => {
          setTimeout(() => {
            this.disableIncidentsLoading()
            this.incidents = data.result
            this.incidents =  this.incidents.map(incident => {
              incident.errorMessage = this.formatIncident(incident.errorMessage)
              return incident
            })
            this.incidentsTotal = this.incidents.length
          },800)
        })
        .catch(error => {
          this.disableIncidentsLoading()
        })
    },
    getIntervenantsList() {
      const accountId = this.accounts.activeAccountId
      const apiToken = localStorage.getItem('token_jwt')

      this.axios.get(`${API_URL}/users/${accountId}/intervenants`,
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`
          }
        }
      ).then(({ data }) => {
        this.intervenants = data.intervenants
      }).catch(() => {})
    },
    formatDate(date) {
      return moment(date).calendar()
    },
    prepareDailyLaunchesData(data) {
      let chartDataSucceededTests = []
      let chartDataFailedTests = []
      let labels = []

      data.forEach(result => {
        chartDataSucceededTests.push(result.successTotal)
        chartDataFailedTests.push(result.failureTotal)
        labels.push(`${result.day}/${this.monthsArray[(result.month)-1]}`)
      })

      DailyCharts[0].chartData.datasets[0].data = chartDataSucceededTests
      DailyCharts[0].chartData.datasets[1].data = chartDataFailedTests
      DailyCharts[0].chartData.labels = labels
    },
    prepareDailyLaunchesRatesData(data) {
      let chartDataSuccessRates = []
      let chartDataFailureRates = []
      let labels = []

      data.forEach(result => {
        chartDataSuccessRates.push(result.successRate)
        chartDataFailureRates.push(result.failureRate)
        labels.push(`${result.day}/${this.monthsArray[(result.month)-1]}`)
      })

      DailyCharts[1].chartData.datasets[0].data = chartDataSuccessRates
      DailyCharts[1].chartData.datasets[1].data = chartDataFailureRates
      DailyCharts[1].chartData.labels = labels
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    reset() {
      this.resetActiveAccountId()
      this.StartDate = START_OF_CURRENT_MONTH
      this.EndDate = TODAY
      this.scenarios.activeScenario = DEFAULT_SCENARIO
      this.Search()
    },
    resetActiveAccountId() {
      this.accounts.activeAccountId = this.accounts.list[0] ? this.accounts.list[0].id : null
    },
    makeChartsDisabled() {
      this.disableCharts = true
    },
    activateChartsLoading() {
      this.Loading.charts = true
    },
    disableStatsLoading() {
      setTimeout(() => {
        this.Loading.stats = false
      },800)
    },
    disableChartsLoading() {
      this.Loading.charts = false
    },
    disableScenariosLoading() {
      this.Loading.scenarios = false
    },
    disableAccountsLoading() {
      this.Loading.accounts = false
    },
    disableIncidentsLoading() {
      this.Loading.incidents = false
    },
    disableAllLoading() {
      Object.keys(this.Loading).forEach(key => this.Loading[key] = false)
    },
    viewIncident(incident) {
      this.showedIncident = incident
    },
    editIncident(incident) {

      if(this.fullscreen) return

      incident.index = this.getIncidentIndex(incident.id, incident.executionDate)
      this.incidentToEdit = {...incident}
    },
    onIncidentEdit() {
      this.resetIncidentToEdit()
      this.getIncidentsList()
    },
    resetIncidentToEdit() {
      this.incidentToEdit = null
    },
    formatIncident(incident) {
      incident = incident.toLowerCase()
      return incident.charAt(0).toUpperCase() + incident.slice(1)
    }
  }
}
</script>

<style>
  .incidents-table-rows{
    text-transform: uppercase;
  }

  .customtable_cmd .cell{
    word-break: break-word;
  }

  .charts-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .centered-loading {
    position: absolute;
    top: 60%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .events-incident, .events-comment {
    text-transform: none;
  }

  .incident-tooltip, .comment-tooltip, .incident-date-tooltip {
    font-size: 13px;
  }

  .incident-date-tooltip {
    text-transform: capitalize;
  }

</style>
