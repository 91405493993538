<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">LEADS DU MOIS</h2>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4">
                    <base-input>
                      <el-select
                        :disabled="$apollo.queries.shootStatMonth.loading"
                        class="select-primary"
                        v-model="filterDates.date"
                      >
                        <el-option
                          v-for="option in filterDates.listeDates"
                          class="select-primary"
                          :value="option.mois_annee"
                          :label="option.label"
                          :key="option.mois_annee"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-8">
                    <base-button
                      v-if="$apollo.queries.shootStatMonth.loading"
                      loading
                      class="btn btn-just-icon btn-default mt-0 mr-3 d-block"
                    >CHARGEMENT</base-button>
                    <button v-else class="btn btn-just-icon btn-default mt-0 d-block">RECHERCHER</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <p class="card-title">
                  <strong>CAMPAGNE:</strong>
                  <span style="color: #988fc3; font-size: 15px;"> {{ extractTag.nom_campagne }}</span>
                </p>
              </div>
            </div>
            <div>
              <base-button
                type="info"
                class="mb-3 pull-right"
                @click="showExtractModal = true"
              >{{ $t("buttons.Extract").toUpperCase() }}</base-button>

              <modal
                :show.sync="showExtractModal"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-listbase"
              >
                <card
                  type="secondary"
                  header-classes="bg-white pb-6"
                  body-classes="px-lg-6 py-lg-6"
                  class="border-0 mb-0"
                >
                  <template>
                    <div class="text-muted text-center mb-5">
                      <h3 class="modal-title">{{ $t("labels.ExtractionFromTag") }}</h3>
                    </div>
                    <div class="text-center text-muted mb-4">
                      <h5 class="modal-title">{{ $t("table.columns.Campagne").toUpperCase() }}</h5>
                      <span
                        class="mb-3"
                        style="color: #988fc3; font-size: 15px;"
                      >{{ extractTag.nom_campagne }}</span>
                    </div>
                    <form role="form">
                      <div class="col-md-8">
                        <base-input :label="$t('table.columns.Startdate')">
                          <el-date-picker
                            v-model="extractTag.start_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="col-md-8">
                        <base-input :label="$t('table.columns.Enddate')">
                          <el-date-picker
                            v-model="extractTag.end_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="2020-01-01"
                          ></el-date-picker>
                        </base-input>
                      </div>
                      <div class="text-center">
                        <base-button type="info" class="my-4" @click="extract()">{{ $t("buttons.Extract").toUpperCase() }}</base-button>
                      </div>
                    </form>
                  </template>
                </card>
              </modal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
                <!-- <h2 class="card-title">Globales</h2> -->
              </div>
            </div>
          </div>
          <div class="p-2" data="black">
            <el-table :data="tableLeads" show-summary :summary-method="getSummaries">
              <el-table-column label="Jour" prop="date" min-width="250">
                <template scope="scope">{{ scope.row.date.toUpperCase() }}</template>
              </el-table-column>
              <el-table-column label="Impressions" prop="impressions" min-width="150">
                <template scope="scope">{{ formatThounsends(scope.row.impressions) }}</template>
              </el-table-column>
              <el-table-column label="Clics" prop="STAT1" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.STAT1) }}</template>
              </el-table-column>
              <el-table-column label="Leads" prop="STAT2" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.STAT2) }}</template>
              </el-table-column>
              <el-table-column label="Ventes" prop="vente" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.vente) + ' €' }}</template>
              </el-table-column>
              <el-table-column label="CA" prop="ca" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.ca, true) + ' €' }}</template>
              </el-table-column>
              <el-table-column label="CTR" prop="leads" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.ctr, true, 2) + ' %' }}</template>
              </el-table-column>
              <el-table-column label="ECPC" prop="ecpc" min-width="100">
                <template scope="scope">{{ formatThounsends(scope.row.ecpc, true, 2) + ' €' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import gql from "graphql-tag";
import { Modal } from "src/components";
import { Table, TableColumn } from "element-ui";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      extractTag: {
        start_date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
        end_date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
        nom_campagne: ""
      },
      showExtractModal: false,
      id_campagne: null,
      firstEntryToPage: true,
      filterDates: {
        date: "",
        listeDates: []
      },
      tableLeads: [],
      leadsTotals: [],
      societeClient: ""
    };
  },
  apollo: {
    shootStatMonth: {
      query: gql`
        query($date: String, $id_campagne: Int, $id_client: Int, $droit: String) {
          shootStatOfMonth(
            date: $date
            id_campagne: $id_campagne
            id_client: $id_client
            droit: $droit
          ) {
            filter_dates {
              mois_annee
            }
            leadsTotals {
              impressions
              clicks
              leads
              ventes
              ecpc
              ca
            }
            leads {
              id_campagne
              id_type_compagne
              nom_campagne
              STAT1
              STAT2
              STAT3
              vente
              prix_annonceur
              date
              annee
              mois
              jour
              ca
              caan
              cae
              ecpc
              ctr
              campagne
              impressions
            }
          }
        }
      `,
      update: data => data.shootStatOfMonth,
      result({ data, loader, networkStatus }) {
        if (this.firstEntryToPage) {
          this.filterDates.date =
            data.shootStatOfMonth[0].filter_dates[0].mois_annee;
          this.firstEntryToPage = false;
        }
        this.filterDates.listeDates = data.shootStatOfMonth[0].filter_dates;
        this.tableLeads = data.shootStatOfMonth[0].leads;
        this.leadsTotals = data.shootStatOfMonth[0].leadsTotals;
        this.extractTag.nom_campagne =
          data.shootStatOfMonth[0].leads[0].nom_campagne;
      },
      variables() {
        return {
          date: this.filterDates.date,
          id_campagne: this.id_campagne,
          droit: 'af'
        };
      }
    }
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0) {
      value != null ? (value = parseFloat(value)) : (value = 0);
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    checkIfNull(value) {
      return value == null ? 0 : value;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        } else if (index === 1) {
          sums[index] = this.formatThounsends(this.leadsTotals["impressions"]);
          return;
        } else if (index === 2) {
          sums[index] = this.formatThounsends(this.leadsTotals["clicks"]);
          return;
        } else if (index === 3) {
          sums[index] = this.formatThounsends(this.leadsTotals["leads"]);
          return;
        } else if (index === 4) {
          sums[index] =
            this.formatThounsends(this.leadsTotals["ventes"]) + " €";
          return;
        } else if (index === 7) {
          sums[index] =
            this.formatThounsends(this.leadsTotals["ecpc"], true, 2) + " €";
          return;
        } else if (index === 6) {
          sums[index] = (this.leadsTotals["clicks"]) ? this.formatThounsends((this.leadsTotals["leads"] / this.leadsTotals["clicks"]) * 100,true,2) + " %" : 0 +" %";
          return;
        } else if (index === 5) {
          sums[index] =
            this.formatThounsends(this.leadsTotals["ca"], true) + " €";
          return;
        } else {
          sums[index] = "";
          return;
        }
      });
      return sums;
    },
    extract() {
      const url_api =
        "https://apimyaffil.wee-do-it.net/api/extract_tag_campagne_editeur";
      let accessToken = localStorage.getItem("token_jwt");
      this.axios
        .post(url_api, this.extractTag, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${accessToken}`
          },
          responseType: "blob"
        })
        .then(response => {
          const filename =
            "Extract_leads_" +
            this.extractTag.nom_campagne +
            "_du_" +
            this.extractTag.start_date +
            "_au_" +
            this.extractTag.end_date +
            ".csv";
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => console.log("error occured"));
    },
  },
  beforeMount() {
    if ((this.id_campagne = localStorage.getItem("id_campagne")) == null) {
      this.$router.push({ name: "liste campagne My Affil Editeur" })
    } else {
      this.id_campagne = localStorage.getItem("id_campagne")
      // localStorage.removeItem("id_campagne")
    }
  }
};
</script>