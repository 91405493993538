<template>
  <modal
    :show.sync="isActive"
    body-classes="p-0"
    class="edit-scenario-modal modal-listbase"
    :modalClasses="modalClasses"
    :centered="true"
  >
    <card
      type="secondary"
      header-classes="bg-white pb-6"
      body-classes="px-lg-10 py-lg-10"
      class="border-0 mb-0"
    >
      <div class="form-row my-2">
        <base-input
          label="Scénario"
          class="col-md-12"
          name="description"
          v-model="scenario.description"
        >
        </base-input>
      </div>
      <center>
        <base-button
          v-if="!editing"
          type="info"
          @click="editScenario()"
          :disabled="editFormInvalid"
          >{{ $t("buttons.BtnModify").toUpperCase() }}
        </base-button>
        <base-button
          v-else
          loading
          round
          type="info"
        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
      </center>
    </card>
  </modal>
</template>

<script>

import { Modal } from "src/components"
import { API_URL } from "../API"

export default {
  components: {
    Modal
  },
  props: {
    scenario: {
      Type: Object,
      required: true,
      default: () => {}
    },
    modalClasses: {
      Type: [String, Object],
      required: false
    }
  },
  data() {
    return {
      isActive: true,
      editing: false,
      descriptionInitialValue: ""
    }
  },
  mounted() {
    this.descriptionInitialValue = this.scenario.description
  },
  watch: {
    isActive(newValue, oldValue) {
      if( ! newValue) {
        this.closeModal()
      }
    }
  },
  computed: {
    editFormInvalid() {
      let description = this.scenario.description
      return ! description || description.trim() == this.descriptionInitialValue || this.doesStringContainEmptySpaces(description)
    }
  },
  methods: {
    doesStringContainEmptySpaces(str) {
      return !str.replace(/\s/g, '').length
    },
    async editScenario() {

      if(this.editFormInvalid) return

      const apiUrl = `${API_URL}/scenarios/${this.scenario.name}`
      const apiToken = localStorage.getItem('token_jwt')
      const description = this.scenario.description.trim()

      this.editing = true

      this.axios.put(
        apiUrl,
        {
          "description": description
        },
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`
          }
        }
      )
        .then(response => {
          setTimeout(() => {
            this.editing = false
            this.$emit('edited')
            this.$notify({type: 'success', message: response.data.message })
          },400)
        })
        .catch(error => {
          let errorMessage = ""
          try {
            errorMessage = error.response.data.message
          } catch (error) {
            errorMessage = "Une erreur est survenue...réessayer plus tard"
          }
          this.editing = false
          this.closeModal()
          this.$notify({type: 'warning', message: errorMessage })
        })

    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style>
  .edit-scenario-modal {
    overflow-y: auto !important;
  }

  .edit-scenario-modal.show .modal-dialog {
    transform: none;
  }

  .edit-scenario-modal .modal-dialog {
    max-width: 700px;
  }

  .edit-scenario-modal .modal-content {
    box-shadow: none;
  }
</style>
