<template>
  <!-- <card> -->
    <!-- <div class="card-body"> -->
      <div v-if="dataVisuel.type !='Banniere' && dataVisuel.type != 'Flux'">
        <center>
          <div class="col-md-6">
            <card type="testimonial" header-classes="card-header-avatar">
              <div slot="header" class="prog">
                <img  :src="visuelImg" alt="Card image" />
              </div>
              <p>
                <strong>Expéditeur :</strong>
                {{ dataVisuel.expediteur }} [
                <strong>le nom de votre base</strong>]
                <br />
                <strong>Objet :</strong>
                {{ dataVisuel.objet }}
                <br />
                <span v-if="dataVisuel.objet2">
                  <strong>Objet :</strong>
                  {{ dataVisuel.objet2 }}
                </span>
              </p>
            </card>
          </div>
        </center>

        <card>
          <p class="card-text">
            <strong>Pour l'emailing</strong> : validation des BAT à
            <a
              style="color:#BDBDBD; text-decoration: underline"
              :href="'mailto:bat@weedoit.fr;'+dataVisuelAnnonceur.manager"
            >bat@weedoit.fr</a> en copie votre traffic manager:
            <a
              style="color:#BDBDBD; text-decoration: underline"
              :href="'mailto:bat@weedoit.fr;'+dataVisuelAnnonceur.manager"
            >{{dataVisuelAnnonceur.manager}}</a>
            avec date et volume d'adresses.
            <br />
            <strong>Important</strong> : Les campagnes ne comportant pas le nom de votre base dans l'exp&eacute;diteur ne seront pas valid&eacute;es
            <br />
          </p>
          <p style="margin-left: 40px">- Préciser la date et volume de shoot en envoyant votre BAT</p>
          <p style="margin-left: 40px">- Avoir comme sender : « #Sender# par [nom de votre base] »</p>
          <p style="margin-left: 40px">- Ne pas mettre de signe « % » dans l'objet.</p>
          <p style="margin-left: 40px">- Ne pas retirer le pixel d'impression</p>
          <p
            style="margin-left: 40px"
          >- Préciser le nom de votre base avant le lien de désabonnement (ex : ce mail vous a été envoyé par [nom de la base])</p>
          <p
            style="margin-left: 40px"
          >- Ajouter si ce n'est pas déjà le cas, une mention avec la loi informatique et liberté + le numéro CNIL dans le footer</p>
          <p>
            Ex : « Vous recevez ce mail car vous êtes abonné(e) à [Nom de la base], enregistrée auprès de la
            CNIL sous le numéro xxxxxx. Conformément à la Loi Informatique
            et Libertés du 6 Janvier 1978, vous disposez d'un droit d'accès,
            de modification et de suppression des données personnelles vous concernant,
            que vous pouvez exercer à tout moment sur demande : contact@nom-de-votre-base.com"
          </p>
        </card>

        <card>
          <p>
            <strong>For emailing</strong>: approval of all previews to be sent at
            <a
              style="color:#BDBDBD; text-decoration: underline"
              :href="'mailto:bat@weedoit.fr;'+dataVisuelAnnonceur.manager"
            >bat@weedoit.fr</a> and in
            copy your Traffic Manager
            <a
              style="color:#BDBDBD; text-decoration: underline"
              :href="'mailto:bat@weedoit.fr;'+dataVisuelAnnonceur.manager"
            >{{dataVisuelAnnonceur.manager}}</a> with date and volume of
            sending
            <br />
            <strong>Important :</strong> Campaigns without the name of your database in the sender will not be approved
          </p>
          <p style="margin-left: 40px">- Specify the date and amount of your shoot by sending BAT</p>
          <p style="margin-left: 40px">- Put as sender: "#Sender# by [your database name]"</p>
          <p style="margin-left: 40px">- Do not put a "%" in the subject</p>
          <p style="margin-left: 40px">- Do not remove the print pixel</p>
          <p
            style="margin-left: 40px"
          >- Specify the name of your database before the unsubscribe link (ex: email that was sent to you by [database name]</p>
          <p
            style="margin-left: 40px"
          >- Add if it is not already the case, a statement with the Data Protection Act + the CNIL number in the footer</p>
          <p>
            Ex: "You are receiving this email because you subscribed(e) [Database Name], registered with the CNIL under the number xxxxxx.
            Under the Data Protection Act of 6 January 1978, you have a right to access, modify and delete your personal data, you can exercise
            at any time on request: contact @ your-name -base.com"
          </p>
        </card>
        <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
          <tr>
            <td align="center">&nbsp;</td>
          </tr>
          <tr>
            <td align="center">
              <table border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <base-button round type="primary" @click="generateVisuel()">
                    <i class="tim-icons el-icon-download"></i> TÉLÉCHARGER
                  </base-button>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>

      <div v-else-if="dataVisuel.type == 'Flux'">
        <table width="100%" border="0" align="center" cellpadding="0" cellspacing="5">
          <tr>
            <td width="15%">URL du flux XML :</td>
            <td>
              <input
                type="text"
                :value="'http://www.weedoit.fr/tracking/flux/flux.php?id='+`${ identifiant }`+'&idf='+dataVisuel.id_programme+fields"
                size
                style="padding: 3px; border: solid 1px #ccc; width: 740px"
                onclick="this.select()"
              />
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
      </div>

      <div v-else>
        <table width="100%" border="0" align="center" cellpadding="0" cellspacing="5">
          <tr>
            <td>
              {{ dataVisuelAnnonceur.provenance }}:
              <strong>{{ dataVisuelAnnonceur.taille }}</strong> image - gif
            </td>
          </tr>
          <tr>
            <td>
              <img
                :src="'http://www.weedoit.fr/tracking/partenaire/'+dataVisuel.repertoire+'/'+dataVisuel.profil"
                border="0"
              />
            </td>
          </tr>
          <tr>
            <td>
              <textarea v-if="dataVisuelAnnonceur.pixel == 'PIXEL'" style="width:700px;height:80px">
                    <a :href="dataVisuel.lien+identifiant">
                      <img :src="'http://www.weedoit.fr/tracking/partenaire/'+dataVisuel.repertoire+'/'+dataVisuel.profil" border="0" />
                    </a>
                    <img width="1" height="1" :src="'http://weedoit.fr/tracking/imp.php?id='+identifiant" />
                  </textarea>

              <textarea v-else style="width:700px;height:80px">
                    <a :href="clickUrl+'&bann='+dataVisuel.id_visuel">
                      <img :src="'http://www.weedoit.fr/tracking/partenaire/'+dataVisuel.repertoire+'/'+dataVisuel.profil" border="0" />
                    </a>
                    <img width="1" height="1" :src="impressionUrl" /></textarea>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
      </div>
    <!-- </div> -->
  <!-- </card> -->
</template>

<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      skipQuery: true,
      Params: null,
      type: "",
      fields: "",
      identifiant: "",
      dataVisuel: [],
      dataVisuelAnnonceur: [],
      visuelImg: ""
    };
  },
  apollo: {
    show_visuel: {
      query: gql`
        query($identifiant: String, $nom_campagne: String, $id_ann: Int) {
          show_visuel(
            identifiant: $identifiant
            nom_campagne: $nom_campagne
            id_ann: $id_ann
          ) {
            data_visuel {
              id_visuel
              id_programme
              format
              taille
              profil
              lien
              expediteur
              objet
              objet2
              repertoire
              url
              etat
              redirect
              tmp
              ip
              hostname
            }
            annonceur {
              manager
              provenance
              pixel
            }
            type
            fields
            clickUrl
            impressionUrl
            visuel_img
          }
        }
      `,
      skip() {
        return this.skipQuery
      },
      update: data => data,
      result({ data, loader, networkStatus }) {
        let visuel = data.show_visuel;
        this.dataVisuel = visuel.data_visuel;
        this.dataVisuelAnnonceur = visuel.annonceur;
        this.identifiant = this.Params.identifiant;
        this.type = visuel.type;
        this.fields = visuel.fields;
        this.visuelImg = visuel.visuel_img
      },
      variables() {
        return {
          identifiant: this.Params.identifiant,
          nom_campagne: this.Params.nom_campagne,
          id_ann: this.Params.id_programme
        };
      }
    }
  },
  methods: {
    generateVisuel() {
      //const url_api = "http://weedoitmyaffil.api/api/generate_visuel"; //for local test
      const url_api = 'https://apimyaffil.wee-do-it.net/api/generate_visuel' //for server test
      let accessToken = localStorage.getItem("token_jwt");
      this.axios
        .post(
          url_api,
          {
            idf: this.identifiant,
            id_visuel: this.dataVisuel.id_visuel,
            campagne: this.Params.nom_campagne,
            track: 3,
            type: (this.type === 'Banniere') ? 'banniere' : 'visuel'
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              authorization: `Bearer ${accessToken}`
            },
            responseType: "blob"
          }
        )
        .then(response => {
          console.log(response.data);

          let filename = this.Params.nom_campagne + ".htm";
          if (this.type === 'Banniere') {
            filename = this.Params.nom_campagne + ".txt";
          }
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => console.log("error occured"));
    }
  },
  beforeMount() {
    if ((this.Params = JSON.parse(localStorage.getItem('params'))) == null) {
      this.$router.push({ name: "liste campagne My Affil Editeur" })
    } else {
      this.skipQuery = false
      localStorage.removeItem("params")
    }
  }
};
</script>

<style scoped>
  .prog {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  .prog img {
    width: 100%;
  }
  </style>