<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <!-- <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
          <router-link
            class="btn btn-round btn-just-icon btn-info"
            :to="{ name: 'Ajouter un Programme'}"
          >AJOUTER UN PROGRAMME</router-link>
        </div> -->
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <h4 class="card-title">{{ $t("labels.News").toUpperCase() }}</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p style="text-align: center">
              <strong>{{ $t("labels.LabelListprg").toUpperCase() }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="carousel3d" v-if="!$apollo.queries.nouveautes.loading">
      <carousel-3d
        :perspective="0"
        :space="220"
        :display="7"
        :controls-visible="true"
        :controls-prev-html="'❬'"
        :controls-next-html="'❭'"
        :controls-width="30"
        :controls-height="30"
        :clickable="true"
        :autoplay="true"
        :autoplay-timeout="5000"
        :width="220"
        :height="300"
      >
        <slide class="costom_slide" v-for="(nouveaute, index) in nouveautes.slice(0,7)" :key="index" :index="index">
          <div class="card card-custom bg-white border-white border-0">
            <div class="card-custom-img" style="background-image: url(/img/cardprogram.jpg);">
              <h6></h6>
              <h6>{{ nouveaute.categorie }}</h6>
              <img
                v-if="nouveaute.hostname != 'plf'"
                :src="'https://www.weedoit.fr/tracking/partenaire/' + nouveaute.repertoire + '/profil-S.png'"
                @error="imageLoadError"
                width="150"
                height="79"
              />
              <img
                v-else
                :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' + nouveaute.repertoire + '/profil-S.png'"
                @error="imageLoadError"
                width="150"
                height="79"
              />
            </div>
            <div class="card-custom-avatar">
              <el-tooltip
                :content="nouveaute.prenom_manager +' '+nouveaute.nom_manager.toUpperCase()"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <template v-if="nouveaute.image_manager == null">
                  <img class="img-fluid" :src="'/img/'+TmPhoto[index]" />
                </template>
                <template v-else>
                  <img class="img-fluid" :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/'+nouveaute.image_manager" />
                </template>
              </el-tooltip>
            </div>
            <div class="card-body" style="overflow-y: auto">
              <h4 class="card-title">
                <template v-if="nouveaute.Titre.length > 15">
                  <!-- <el-tooltip
                    :content="Editer"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  > -->
                  {{ nouveaute.Titre.slice(0,15) }}...<br/> <br />
                  <!-- </el-tooltip> -->
                </template>
                <template v-else="">
                  {{ nouveaute.Titre }}<br /><br />
                </template>
              </h4>
              <p class="card-text">
                <template
                  style="color:#000; font-size:14px"
                  v-if="nouveaute.campagne === 'lead'"
                >{{ nouveaute.prix_editeur }} €/lead</template>
                <template
                  style="color:#000; font-size:14px"
                  v-else-if="nouveaute.campagne === 'affichage'"
                >{{ nouveaute.prix_editeur_cpc }} €/clic</template>
                <template
                  style="color:#000; font-size:14px"
                  v-else-if="nouveaute.campagne === 'cpm'"
                >{{ nouveaute.prix_editeur_cpm }} €/CPM</template>
                <template
                  style="color:#000; font-size:14px"
                  v-else-if="nouveaute.campagne === 'vente fixe'"
                >{{ nouveaute.prix_editeur_cpa_fixe }} €/cpa</template>
                <template
                  style="color:#000; font-size:14px"
                  v-else-if="nouveaute.campagne === 'sms'"
                >{{ nouveaute.prix_editeur_sms }} €/sms</template>
                <template
                  style="color:#000; font-size:14px"
                  v-else-if="nouveaute.campagne === 'pourcentage des ventes'"
                >{{ nouveaute.prix_editeur_sms }} %/cpa</template>
                <template
                  style="color:#000; font-size:14px"
                  v-else-if="nouveaute.campagne === 'téléchargement'"
                >{{ nouveaute.prix_editeur_cpd }} €/cpd</template>
                <br />
                <!-- TM : {{ nouveaute.manager }} -->
                TM :
                {{ nouveaute.nom_manager.toUpperCase()}}
                {{nouveaute.prenom_manager}}
              </p>
            </div>
            <div class="card-footer" style="background: inherit; border-color: inherit;">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <label @click="handleEdit(nouveaute)" style="font-size: 0.75rem" class="btn btn-sm btn-primary btn-simple active" id="0">
                  <input type="radio" name="options" checked />
                  <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnApply").toUpperCase() }}</span>
                  <span class="d-block d-sm-none">
                    <i class="tim-icons icon-send"></i>
                  </span>
                </label>
                <label @click="Visualiser(nouveaute)" style="font-size: 0.75rem" class="btn btn-sm btn-primary btn-simple" id="1">
                  <input type="radio" class="d-none d-sm-none" name="options" />
                  <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnLearnMore").toUpperCase() }}</span>
                  <span class="d-block d-sm-none">
                    <i class="tim-icons icon-tv-2"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </slide>
      </carousel-3d>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card>
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="MANAGER"
                    :disabled="$apollo.queries.listManager.loading"
                    v-model="ManagerSelects.Manager"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ManagerSelects.ListManager"
                      class="select-primary"
                      :value="option.email_client"
                      :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                      :key="option.id_client"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <!-- <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="ANNONCEUR"
                    :disabled="$apollo.queries.listAnnonceur.loading"
                    v-model="AnnonceurSelects.Annonceur"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in AnnonceurSelects.ListAnnonceur"
                      class="select-primary"
                      :value="option.id_client"
                      :label="option.societe_client.toUpperCase()"
                      :key="option.id_client"
                    ></el-option>
                  </el-select>
                </base-input>
              </div> -->
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="MODE DE RÉMUNÉRATION"
                    :disabled="$apollo.queries.listModeRemuneration.loading"
                    v-model="ModeRemunerationSelects.ModeRemuneration"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ModeRemunerationSelects.ListModeRemuneration"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="THÉMATIQUE"
                    :disabled="$apollo.queries.listCategories.loading"
                    v-model="ThematiqueSelects.Thematique"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ThematiqueSelects.ListThematique"
                      class="select-primary"
                      :value="option.value"
                      :label="option.categorie.toUpperCase()"
                      :key="option.value"
                    >
                      {{ option.categorie.toUpperCase() }}<i :class="option.icon+'  pull-right pt-2'"></i>
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="TYPE DE DIFFUSION"
                    v-model="TypeDiffusionSelects.TypeDiffusion"
                    clearable
                  >
                    <el-option
                      v-for="option in TypeDiffusionSelects.ListTypeDiffusion"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.label"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="PAYS"
                    v-model="PaysSelects.Pays"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="option in PaysSelects.ListPays"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.label"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input type="text" placeholder="MOTS CLÉS" v-model="keyword"></base-input>
              </div>
            </div>
          </div>
          <div class="text-right">
            <base-button
              v-if="$apollo.queries.listAnnonces.loading"
              loading
              class="btn btn-just-icon btn-default mt-0 d-block"
            ></base-button>
            <button v-else class="btn btn-just-icon btn-default" @click="reset()">{{ $t("buttons.BtnReset").toUpperCase() }}</button>
          </div>
        </card>
        <card v-if="!$apollo.queries.listManager.loading">
          <div>
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <el-table :data="filterlistAnnonces">
              <el-table-column 
                prop="Titre" 
                label="Campagne" 
                class="table-striped" 
                min-width="270"
                sortable>
                <template scope="scope">
                  <div class="prog mr-2 float-left">
                    <img
                      v-if="scope.row.hostname != 'plf'"
                      :src="'https://www.weedoit.fr/tracking/partenaire/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      @click="Visualiser(scope.row)"
                      style="cursor: pointer;"
                    />
                    <img
                      v-else
                      :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      @click="Visualiser(scope.row)"
                      style="cursor: pointer;"
                    />
                  </div>
                  <div class="pt-3">
                    
                    <template v-if="scope.row.Titre.length <= 20">
                      {{ scope.row.Titre.toUpperCase() }}
                    </template>
                    <template v-else>
                      <el-tooltip
                        :content="scope.row.Titre"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <label>
                          {{ scope.row.Titre.slice(0,20).toUpperCase() }}...
                        </label>
                      </el-tooltip>
                    </template>
                  
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="societe_client"
                label="Annonceur"
                class="table-striped"
                min-width="150"
                sortable
                show-overflow-tooltip
                >
                <template scope="scope">
                  {{ scope.row.societe_client.toUpperCase() }}
                </template>
              </el-table-column> -->
              <el-table-column 
                label="Rémunération" 
                class="table-striped"
                min-width="160">
                <template scope="scope">
                  

                  <template
                    style="color:#000; font-size:14px"
                    v-if="scope.row.campagne.includes('lead')"
                  >{{ scope.row.prix_editeur }} €/lead </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('affichage')"
                  >{{ scope.row.prix_editeur_cpc }} €/clic </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('cpm')"
                  >{{ scope.row.prix_editeur_cpm }} €/cpm </template>
                  <template 
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('vente fixe')"
                  >
                    {{ scope.row.prix_editeur_cpa_fixe }} €/cpa  
                  </template>
                  <template 
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('sms')"
                  >
                    {{ scope.row.prix_editeur_sms }} €/sms  
                  </template>
                  <template 
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('pourcentage des ventes')"
                  >
                    {{ scope.row.prix_editeur_cpa }} %/cpa  
                  </template>
                  <template 
                    style="color:#000; font-size:14px"
                    v-else
                  >
                    {{ scope.row.prix_editeur_cpd }} €/cpd
                  </template>

                </template>
              </el-table-column>
              <el-table-column 
                prop="categorie" 
                label="thématique" 
                class="table-striped" 
                min-width="150"
                sortable
                show-overflow-tooltip>
                  <template scope="scope">
                    <template v-if='ThematiqueSelects.ListThematique.find(c => c.value == scope.row.categorie)'>
                      <el-tooltip
                        :content="scope.row.categorie" 
                        effect="light" 
                        :open-delay="300" 
                        placement="top">
                          <i :class="ThematiqueSelects.ListThematique.find(c => c.value == scope.row.categorie).icon"></i>
                      </el-tooltip>
                    </template>
                    <template v-else>
                      {{ scope.row.categorie }}
                    </template>
                  </template>
              </el-table-column>
              <el-table-column 
                prop="manager" 
                label="Manager" 
                min-width="260"
                class="table-striped" 
                sortable>
                <template scope="scope">
                    <template v-if="scope.row.image_manager == null">
                      <div class="tm_photo mr-2 float-left">
                        <img :src="'/img/'+ TmPhoto[(scope.$index < 7)? scope.$index : 2]" alt="photo" />
                      </div>
                    </template>
                    <template v-else>
                      <div class="tm_photo mr-2 float-left">
                        <img :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/'+scope.row.image_manager" alt="photo"/>
                      </div>
                    </template>
                  <div class="pt-1">{{ scope.row.manager }}</div>
                </template>
              </el-table-column>
              <el-table-column 
                prop label="Action" 
                class="table-striped" 
                min-width="100">
                <template scope="props">
                  <el-tooltip
                    content="POSTULER"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="handleEdit(props.row)"
                      class="edit btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-send"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('buttons.BtnView').toUpperCase()"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="Visualiser(props.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-tv-2"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p
                class="card-category"
              >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
    <!-- POSUTULER modal -->
    <modal
      :show.sync="PostuleShow"
      footerClasses="justify-content-center"
      body-classes="p-0"
      class="modal-listbase"
    >
      <card class="border-0 mb-0">
        <base-button style="float: right;" @click.native="PostuleShow = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <h3>
          LISTE DES SUPPORTS
        </h3>
        <el-table :data="listbase">
          <el-table-column
            prop="nom_support"
            label="Nom de support" 
            class="table-striped" 
            min-width="280"
            sortable
          >
            <template scope="scope">
              {{ scope.row.nom_support.toUpperCase() }}
            </template>
          </el-table-column>
          <el-table-column
            prop="audience"
            label="Volume" 
            class="table-striped" 
            min-width="150"
            sortable
          >
            <template scope="scope">
              {{ scope.row.audience.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
            </template>
          </el-table-column>  
          <el-table-column
            prop="pays"
            label="Pays" 
            class="table-striped" 
            min-width="150"
            sortable
          >
            <template scope="scope">
              {{ scope.row.pays.toUpperCase() }}
            </template>
          </el-table-column>
          <el-table-column 
            prop label="Action" 
            class="table-striped" 
            min-width="120">
            <template scope="props">
              <el-tooltip
                content="POSTULER"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <base-button
                  @click.native="postule(props.row)"
                  class="edit btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-send"></i>
                </base-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center">
          <base-button
            type="info"
            @click.native="PostuleShow = false; AddBaseShow = true"
            >AJOUTER UN SUPPORT
          </base-button>
        </div>
      </card>
    </modal>
    <!-- POSUTULER modal -->

    <!-- ADD SUPPORT modal -->
    <modal
      :show.sync="AddBaseShow"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <base-button style="float: right;" @click.native="AddBaseShow = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <h3>
          AJOUTER UN SUPPORT
        </h3>
        <div class="col-sm-12">
          <base-input 
            label="NOM DE SUPPORT"
            placeholder="NOM DE SUPPORT"
            :error="getError('nom de support')"
            name="nom de support"
            v-validate="'required'"
            v-model="NameSupport"
          >
          </base-input>
        </div>
        <div class="col-sm-12">
          <base-input label="TYPE DE DIFFUSION" :error="getError('type de diffusion')">
            <el-select filterable multiple class="select-primary"
              v-validate="'required'"
              name="type de diffusion"
              placeholder="TYPE DE DIFFUSION"
              v-model="TypeSupport">
              <el-option 
                v-for="option in supportTypes"
                class="select-primary"
                :value="option.value"
                :label="option.type"
                :key="option.value">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-sm-12">
          <base-input label="AUDIENCE"
          placeholder="AUDIENCE"
          :error="getError('audience')"
          name="audience"
          v-validate="{required:true,numeric:true}"
          v-model="VolumeSupport"
          >
          </base-input>
        </div>
        <div class="col-sm-12">
          <base-input label="URL DU MEDIA" placeholder="https://www.wee-do-it.net/..." v-model="UrlSupport"></base-input>
        </div>
        <div class="col-sm-12">
          <base-input label="PAYS" :error="getError('pays')">
            <el-select filterable class="select-primary"
              v-validate="'required'"
              name="pays"
              placeholder="PAYS"
              clearable
              v-model="PaysSupport">
              <el-option 
                v-for="option in LangueSelects.LangueList"
                class="select-primary"
                :value="option.name"
                :label="option.name.toUpperCase()"
                :key="option.iso2">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-sm-12">
          <base-input label="APPETENCES" :error="getError('appetences')">
            <el-select
              name="appetences"
              v-validate="'required'"
              class="select-primary"
              placeholder="APPETENCES"
              :disabled="$apollo.queries.listCategories.loading"
              v-model="ThematiqueSupport"
              filterable
              clearable
            >
              <el-option
                v-for="option in ThematiqueSelects.ListThematique"
                class="select-primary"
                :value="option.value"
                :label="option.categorie.toUpperCase()"
                :key="option.value"
              ></el-option>
            </el-select>
          </base-input>
        </div>
        <div  class="text-center">
          <base-button
            type="info"
            @click.native="AddBase"
            >AJOUTER
          </base-button>
        </div>
      </card>
    </modal>
    <!-- ADD SUPPORT modal -->

    <div>
      <modal
        :show.sync="showDateShootPlanificationModal"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-listbase"
      >
        <card
          type="secondary"
          header-classes="bg-white pb-6"
          body-classes="px-lg-6 py-lg-6"
          class="border-0 mb-0"
        >
          <template>
            <div class="text-muted text-center mb-5">
              <h3 class="modal-title">PLANIFICATION DU SHOOT</h3>
            </div>
            <form role="form">
              <div class="col-md-12">
                <base-input label="DATE DE PLANIFICATION">
                  <el-date-picker
                    v-model="dateShootPlanification"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="2020-01-01"
                    :disabledDates="disabledDates"
                  ></el-date-picker>
                </base-input>
              </div>
              <div class="text-center">
                <base-button type="info" class="my-4" @click="checkPlanificationDate">VALIDER</base-button>
              </div>
            </form>
          </template>
        </card>
      </modal>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import { Progress } from "src/components";
import { Table, TableColumn } from "element-ui";
import gql from "graphql-tag";
import { BaseProgress } from "@/components";
import { BasePagination } from "src/components";
import { Carousel3d, Slide } from "vue-carousel-3d";
import {Modal} from 'src/components'
import swal from 'sweetalert2'
import { Thematiques } from "src/pages/Filters/Thematiques";

export default {
  apollo: {
    nouveautes: {
      query: require("src/graphql/NewCampagnes.gql"),
      variables: {
        role: "af"
      },
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {}
    },
    listManager: {
      query: gql`
        query {
          listManager(
            bloque: "non"
            etat: "valide"
            droit: ["ma", "tem"]
            orderBy: [
              { field: "prenom_client", order: ASC }
              { field: "nom_client", order: ASC }
            ]
          ) {
            id_client
            nom_client
            prenom_client
            email_client
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.ManagerSelects.ListManager = data.listManager;
      }
    },
    // listAnnonceur: {
    //   query: gql`
    //     query {
    //       listAnnonceur {
    //         id_client
    //         email_client
    //         societe_client
    //       }
    //     }
    //   `,
    //   result({ data, loader, networkStatus }) {
    //     this.AnnonceurSelects.ListAnnonceur = data.listAnnonceur;
    //   }
    // },
    listModeRemuneration: {
      query: gql`
        query {
          listModeRemuneration {
            campagne
          }
        }
      `,
      result({ data, loader, networkStatus }) {}
    },
    listCategories: {
      query: gql`
        query {
          listCategories {
            name
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        //this.ThematiqueSelects.ListThematique = data.listCategories;
      }
    },
    listAnnonces: {
      query: require("src/graphql/ListprogramWeedoPerf.gql"),
      fetchPolicy: 'network-only',
      update: data => data.filterAnnonces,
      variables() {
        return {
          id_client: null,
          droit: "af",
          langue: "FR"
        }
      },
    },
    liste_supports: {
      query: gql`
        query ($id_client: ID){
          liste_supports (id_client:$id_client){
            id_support
            id_bases
            id_client
            nom_support
            support_types
            url_media
            pays
            audience
            etat
            date_creation
          },
          listPays(iso2:""){
            iso2
            name
          }
          pays_bases{
            pays
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.listbase = data.liste_supports
        this.LangueSelects.LangueList = data.listPays
      },
      variables() {
        return {
          id_client: this.id_utilisateur
        }
      }
    }
  },
  components: {
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseProgress,
    Carousel3d,
    Slide,
    Modal
  },
  data() {
    return {
      AddBaseShow: false,
      PostuleShow: false,
      TmPhoto:["emilyz.jpg","james.jpg","jana.jpg","lora.jpg","mike.jpg","robi.jpg","tania.jpg"],
      id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
      id_client: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil,
      listbase: [],
      ProgramPostule: null,
      nouveautes: null,
      listAnnonces: null,
      keyword: "",
      ManagerSelects: {
        Manager: "",
        ListManager: []
      },
      AnnonceurSelects: {
        Annonceur: "",
        ListAnnonceur: []
      },
      ModeRemunerationSelects: {
        ModeRemuneration: "",
        ListModeRemuneration: [
          { value: "affichage", label: "CPC" },
          { value: "cpm", label: "CPM" },
          { value: "Enrichissement", label: "ENRICHISSEMENT" },
          { value: "lead", label: "CPL" },
          { value: "pourcentage des ventes", label: "CPA" },
          { value: "sms", label: "SMS" },
          { value: "CPD", label: "CPD" },
          { value: "vente fixe", label: "CPA Fixe" }
        ]
      },
      ThematiqueSelects: {
        Thematique: "",
        ListThematique: Thematiques
      },
      TypeDiffusionSelects: {
        TypeDiffusion: "",
        ListTypeDiffusion: [
          // { value: "emailing", label: "E-mailing" },
          // { value: "banniere", label: "Bannière" },
          // { value: "popunder", label: "Pop-under" },
          // { value: "siteunder", label: "Site-under" },
          // { value: "inc_clic", label: "Incentive au clic" },
          // { value: "inc_rempli", label: "Incentive au remplissage" },

          { value: "Avantages consommateurs", label: "Avantages consommateurs" },
          { value: "Content & Influence marketing", label: "Content & Influence marketing" },
          { value: "emailing", label: "Email Marketing" },
          { value: "Emulation", label: "Emulation" },
          { value: "Génération de leads", label: "Génération de leads" },
          { value: "Iframe", label: "Iframe" },
          { value: "Mailing", label: "Mailing" },
          { value: "Mobile", label: "Mobile" },
          { value: "Native", label: "Native" },
          { value: "Push notification", label: "Push notification" },
          { value: "Remarketing", label: "Remarketing" },
          { value: "Reseau sociaux", label: "Reseau sociaux" },
          // { value: "Search", label: " Reseau sociaux" },
          { value: "SMS", label: "SMS" }
        ]
      },
      LangueSelects: {
        Langue: '',
        LangueList: []
      },
      PaysSelects: {
        Pays: 'fr',
        ListPays: [
          {value: "de", label: "Allemagne"}, 
          {value: "ads", label: "Afrique du Sud"}, 
          {value: "aus", label: "Australie"}, 
          {value: "be", label: "Belgique"}, 
          {value: "br", label: "Brésil"}, 
          {value: "da", label: "Danemark"},
          {value: "uae", label: "Émirats arabes unis"}, 
          {value: "es", label: "Espagne"}, 
          {value: "us", label: "États-Unis"}, 
          {value: "fi", label: "Finlande"}, 
          {value: "fr", label: "france"}, 
          {value: "it", label: "Italie"}, 
          {value: "lux", label: "Luxembourg"},
          {value: "no", label: "Norvège"}, 
          {value: "nz", label: "Nouvelle-Zélande (New Zealand)"}, 
          {value: "nl", label: "Pays-Bas"}, 
          {value: "po", label: "Pologne"}, 
          {value: "pt", label: "Portugal"}, 
          {value: "uk", label: "Royaume-Uni (United Kingdom)"}, 
          {value: "ch", label: "Suisse"}, 
          {value: "se", label: "Suède"}
        ]
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 25, 100, 250],
        total: 0
      },
      total: 0,
      supportTypes: [
        {value: '1', type:'APPLI MOBILE'},
        {value: '2', type:'BASE EMAILS'},
        {value: '3', type:'BASE SMS'},
        {value: '4', type:'COMPARATEUR DE PRIX'},
        {value: '5', type:'DISPLAY'},
        {value: '6', type:'EMULATION'},
        {value: '7', type:'FACEBOOK'},
        {value: '8', type:'INSTAGRAM'},
        {value: '9', type:'LINKEDIN'},
        {value: '10', type:'PUSH NOTIFS'},
        {value: '11', type:'RETARGETING'},
        {value: '12', type:'SNAPCHAT'},
        {value: '13', type:'SEO/SEA'},
        {value: '14', type:'TWITTER'},
        {value: '15', type:'YOUTUBE'}
      ],
      NameSupport: "",
      VolumeSupport: "",
      PaysSupport: "",
      TypeSupport: "",
      ThematiqueSupport: "",
      UrlSupport: "",
      dateShootPlanification: null,
      showDateShootPlanificationModal: false,
      postuleRow: {},
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      }
    };
  },
  methods: {
    Visualiser(row) {
      localStorage.setItem('ProgrammeVisualiser', JSON.stringify(row))
      this.$router.push({name: "Visualiser programme My Affil Editeur"})
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    imageLoadError(event) {
      event.target.src =
        "/img/missingprofile.jpg";
    },
    reset() {
      this.ManagerSelects.Manager = "";
      this.AnnonceurSelects.Annonceur = "";
      this.ModeRemunerationSelects.ModeRemuneration = "";
      this.ThematiqueSelects.Thematique = "";
      this.TypeDiffusionSelects.TypeDiffusion = "";
      this.keyword = "";
    },
    handleEdit(row) {
      this.ProgramPostule = row
      this.PostuleShow = true
    },
    async AddBase() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      
      const resultQuery = this.$apollo.mutate({
        mutation: gql`mutation (            
            $id_utilisateur: Int!
            $audience: Int!
            $pays: String!
            $thematique: String!
            $nom_support: String!
            $support_types: String!
          ) {
            addNewSupport (
                        id_utilisateur: $id_utilisateur
                        audience: $audience
                        pays: $pays
                        thematique: $thematique
                        nom_support: $nom_support
                        support_types: $support_types
                      )
                    {
                      id_support
                      id_client
                      nom_support
                      support_types
                      url_media
                      pays
                      audience
                      etat
                      thematique
                      date_creation
                    }
        }`,
        variables: {
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
          audience: this.VolumeSupport,
          pays: this.PaysSupport,
          thematique: this.ThematiqueSupport,
          nom_support: this.NameSupport,
          support_types: this.TypeSupport.join(';')
        },
      }).then(data => {
            var message = "";
            if (data.data.addNewSupport.id_support) {
              this.listbase.unshift(data.data.addNewSupport)
              this.AddSupportShow = false
              message = "LE SUPPORT A BIEN ÉTÉ AJOUTÉ MAIS ENCORE DE VALIDATION"
              this.$notify({
                type: "success",
                verticalAlign: "top",
                horizontalAlign: "right",
                message: message,
                duration: 5000
              });

              this.AddBaseShow = false
              this.NameSupport = ""
              this.VolumeSupport = ""
              this.PaysSupport = ""
              this.TypeDiffusionSupport = ""
              this.ThematiqueSupport = ""
            }
          })
          .catch(error => {
            // Error
            console.error(error);
          });
    },
    checkPlanificationDate(){
      let message
      if(this.dateShootPlanification == null){
        message = "CONFIGUREZ LA DATE DE PLANIFICATION S'IL VOUS PLAIT."
        this.$notify({
          type: "warning",
          verticalAlign: "top",
          horizontalAlign: "right",
          message: message,
          duration: 5000
        });
        return false
      }else{
        this.showDateShootPlanificationModal = false
        this.postule(this.postuleRow)
      }
    },
    postule(row){
      if(this.dateShootPlanification == null){
        this.showDateShootPlanificationModal = true
        this.postuleRow = row
      }else{
        swal.fire({
          title: 'ÊTES-VOUS SÛR?',
          text: "VOUS ÊTES EN TRAIN DE POSTULER",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'POSTULER',
          cancelButtonText: 'ANNULER'
        }).then(result =>{
          if(result.value) {
            let timerInterval
            swal.fire({
              //title: 'Auto close alert!',
              html: 'VOTRE DEMANDE EST EN COURS...',
              //timer: 10000,
              timerProgressBar: true,
              onBeforeOpen: () => {
                swal.showLoading()
                timerInterval = setInterval(() => {
                  const content = swal.getContent()
                  if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                      b.textContent = swal.getTimerLeft()
                    }
                  }
                }, 100)
              },
              onClose: () => {
                clearInterval(timerInterval)
              }
            })
            this.$apollo.mutate({
              mutation: gql`mutation ($societe_client: String!, $id_type_compagne: String!, $id_programme: Int!, $id_bases: Int!, $date_shoot_planification: String) {
                postuler 
                (
                  societe_client: $societe_client
                  id_type_compagne: $id_type_compagne
                  id_programme: $id_programme
                  id_bases: $id_bases
                  date_shoot_planification: $date_shoot_planification
                )
                {
                  id_postule
                  id_client
                  id_type_compagne
                  id_programme
                  id_bases
                  etat
                }
              }`,
              variables: {
                societe_client: JSON.parse(localStorage.getItem('utilisateur')).society,
                id_type_compagne: "Visuel",
                id_programme: this.ProgramPostule.id_programme,
                date_shoot_planification: this.dateShootPlanification,
                id_bases: row.id_bases
              },
            }).then(data => {
              
              if(data.data.postuler)
              {
                
                clearInterval(timerInterval)
                swal.fire(
                  'ENVOYÉE!',
                  'VOTRE DEMANDE DE DIFFUSION POUR CET OFFRE A ÉTÉ ENVOYÉE.',
                  'success'
                )
                this.dateShootPlanification = null
              }
            })
          }else if(swal.DismissReason.cancel == 'cancel'){
            this.dateShootPlanification = null
          }
        })
      }
    }
  },
  computed: {
    filterlistAnnonces() {
      var list = null;
      try {
        list = this.listAnnonces.filter(annonce => {
          return (
            annonce.categorie.match(this.ThematiqueSelects.Thematique) &&
            annonce.manager.match(this.ManagerSelects.Manager) &&
            // (annonce.id_client + "").match(this.AnnonceurSelects.Annonceur) &&
            annonce.format.match(this.TypeDiffusionSelects.TypeDiffusion) &&
            annonce.campagne.match(
              this.ModeRemunerationSelects.ModeRemuneration
            ) &&
            annonce.langue.toUpperCase().match(this.PaysSelects.Pays.toUpperCase()) &&
            annonce.Titre.toUpperCase().match(this.keyword.toUpperCase())
          );
        });
        this.total = list.length;
        if(list != null) {
          return list.slice(this.from, this.to);
        }
        else
          return null
      } catch (error) {
        return null;
      }
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  }
};
</script>

<style scoped>
.btn {
  white-space: nowrap;
}
.prog {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.prog img {
  width: 100%;
}
.tm_photo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.tm_photo img {
  width: 100%;
}
.phototm {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  box-shadow: 0px 0px 5px #000;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}

.campagnes {
  background: #ffffff;
  padding: 10px 0px;
  margin: 5px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 2px #999;
  background: linear-gradient(0deg, #51357c 0%, #988fc3 65%, #ffffff 66%);
  border: 3px solid #fff !important;
}
.campagnes h6 {
  background: #51357c;
  color: #fff;
  margin: -10px auto 10px;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 80%;
  font-size: 12px;
}
.campagnes h5 {
  background: #fff;
  margin: 10px 0px;
  padding: 5px 0;
  color: #54347c;
  font-weight: bold;
}
.campagnes img {
  box-shadow: 0px 0px 2px #999;
}
.campagnes button {
  width: 80%;
}
.campagnes button.btn {
  padding: 5px;
}

.card .card-header h5.card-category {
  color: #fff;
}
.white-content .card .card-header h5.card-category {
  color: #1d253b;
}
.white-content .btn-neutral.btn-simple {
  color: #000;
  border-color: #000;
  background: transparent;
}

/** Card **/
.card-custom {
  overflow: hidden;
  min-height: 300px;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  width: 200px;
  margin: 0 auto;
}
.card-custom .card-body {
  margin-top: -75px;
  text-align: right;
  padding: 15px 15px 0;
  z-index: 999999;
}
.card-custom .card-body p {
  text-align: left;
}
.card-custom-img {
  height: 200px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-color: inherit;
}
.card-custom-img h6 {
  text-transform: none;
}
.card-custom .card-body .card-title {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
/*#program .card-custom .card-body .card-title {min-height:60px}*/
.card-custom .card-body .card-text {
  color: #000;
}
/* First border-left-width setting is a fallback */
#program .card-footer {
  margin-top: 0;
}
.card-custom-img::after {
  position: absolute;
  content: "";
  top: 161px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 40px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 545px;
  border-left-width: calc(575px - 5vw);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
}

.card-custom-avatar img {
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  position: absolute;
  z-index: 99999999;
  top: 120px;
  left: 1.25rem;
  width: 35px;
  height: 35px;
}
</style>

<style>
#carousel3d .carousel-3d-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  padding: 0px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 10px;
}

#carousel3d .carousel-3d-slide img {
  width: auto !important;
}
#carousel3d .carousel-3d-slide .card {
  margin-bottom: 0px;
}

#carousel3d .carousel-3d-controls a.prev,
#carousel3d .carousel-3d-controls a.next {
  color: #fff;
}
#carousel3d .carousel-3d-slider {
  position: relative;
  margin: 0 auto;
  transform-style: none !important;
  -webkit-perspective: none !important;
  -moz-perspective: none !important;
  perspective: none !important;
  width: 200px !important;
}
.costom_slide {
  height: 295px !important;
  width: 200px !important;
  padding: 10px;
}
</style>