<template>
	<card>
		<div class="row justify-content-center">
			<div class="col-sm-10">
				<h4>En tant qu'affili&eacute; international, vous devez fournir :</h4>
				<ul>
					<li><b>Titulaire du compte</b></li>
					<li><b>Nom de votre banque</b></li>
					<li><b>Domiciliation</b></li>
					<li>
						<b>IBAN</b> : entre 14 et 34 caract&egrave;res (2 lettres code pays, 2
						chiffres cl&eacute; de contr&ocirc;le, num&eacute;ro de compte)
					</li>
					<li>
						<b>SWIFT / BIC</b> : 8 ou 11 caract&egrave;res (code banque, code pays,
						code emplacement, code branche optionnel)
					</li>
					<li>
						<b>Num&eacute;ro de TVA (facultatif)</b> : si vous &ecirc;tes assuj&eacute;ti à la TVA
					</li>
				</ul>
				<br />
			</div>
			<div class="col-sm-5">
				<base-input
					label="Titulaire du compte * :"
					placeholder=""
					v-model="infosBancaireInter.titulaire"
					v-validate="'required|max:50|min:3'"
					:error="getError('titulaire')"
					name="titulaire"
					type="text"
				>
				</base-input>
			</div>
			<div class="col-sm-5">
				<base-input
					label="Nom de votre banque * :"
					placeholder=""
					v-model="infosBancaireInter.nom_banque"
					v-validate="'required|max:100|min:3'"
					:error="getError('nom_banque')"
					name="nom_banque"
					type="text"
				>
				</base-input>
			</div>
			<div class="col-sm-5">
				<base-input
					label="Domiciliation * :"
					placeholder=""
					v-model="infosBancaireInter.domiciliation"
					v-validate="'required'"
					:error="getError('domi')"
					name="domi"
					type="text"
				>
				</base-input>
			</div>
			<div class="col-sm-5">
				<base-input
					:label="'IBAN : ' + substr_replace(infosBancaireInter.iban, '************', 4)"
					placeholder=""
					v-model="iban"
					v-validate="'required|max:34|min:14'"
					:error="getError('iban')"
					name="iban"
					type="text"
				>
				</base-input>
			</div>
			<div class="col-sm-5">
				<base-input
					label="SWIFT / BIC * :"
					placeholder=""
					v-model="infosBancaireInter.swift"
					v-validate="'required|max:11|min:8'"
					:error="getError('swift')"
					name="swift"
					type="text"
				>
				</base-input>
			</div>
			<div class="col-sm-5">
				<base-input
					label="Numéro de TVA :"
					placeholder=""
					v-model="infosBancaireInter.tva"
					name="tva"
					type="text"
				>
				</base-input>
			</div>
			<div class="col-sm-5"></div>
		</div>
		<div class="row justify-content-center">
			<div class="col-sm-5">
				* informations obligatoires
			</div>
			<div class="col-sm-5">
				<base-button
					type="info"
					v-if="!loader"
					@click="addOrUpdateInfos"
					class="space"
					>ENREGISTRER</base-button
				>
				<base-button type="info" loading class="space" v-else
					>ENREGISTRER...</base-button
				>
			</div>
		</div>
	</card>
</template>

<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn } from "element-ui";
import { BasePagination } from "src/components";
import { BaseRadio, BaseSwitch } from "src/components/index";
import gql from "graphql-tag";

export default {
	apollo: {
		infosbancaire: {
			client: "AUTH",
			query: gql`
				query($id_affailie: ID) {
					infosBancaire(id_affailie: $id_affailie) {
						id
						international
						titulaire
						nom_banque
						n_compte
						domiciliation
						iban
						swift
						tva
					}
				}
			`,
			update: data => data,
			result({ data, loader, networkStatus }) {
				if (data.infosBancaire != null) {
                    if(data.infosBancaire.international == 1){
                        this.infosBancaireInter = data.infosBancaire;
                    }
				}
				this.loader = false;
			},
			variables() {
				return {
					id_affailie: JSON.parse(localStorage.getItem("utilisateur")).id
				};
			}
		}
	},
	components: {
		BasePagination,
		BaseSwitch,
		BaseRadio,
		[Select.name]: Select,
		[Option.name]: Option,
		[Table.name]: Table,
		[TableColumn.name]: TableColumn
	},
	data() {
		return {
			infosBancaireInter: {
				id: null,
				international: 1,
				titulaire: "",
				nom_banque: "",
				n_compte: "",
				domiciliation: "",
				iban: "",
				swift: "",
				tva: ""
			},
			iban: "",
			loader: true
		};
	},
	methods: {
		getError(fieldName) {
			return this.errors.first(fieldName);
		},
		async addOrUpdateInfos() {
			let infosBancaireInter = this.infosBancaireInter;
			let validate;
			await this.$validator.validateAll().then(isValid => {
				validate = isValid;
			});
			if (!validate) return;
			this.loader = true;
			this.$apollo
				.mutate({
					client: "AUTH",
					mutation: gql`
						mutation(
							$id_affailie: Int
							$international: String
							$titulaire: String
							$nom_banque: String
							$n_compte: String
							$domiciliation: String
							$iban: String
							$swift: String
							$tva: String
						) {
							addOrUpdateInfosBanc(
								id_affailie: $id_affailie
								international: $international
								titulaire: $titulaire
								nom_banque: $nom_banque
								n_compte: $n_compte
								domiciliation: $domiciliation
								iban: $iban
								swift: $swift
								tva: $tva
							) {
								id
								international
								titulaire
								nom_banque
								n_compte
								domiciliation
								iban
								swift
								tva
							}
						}
					`,
					variables: {
						id_affailie: JSON.parse(localStorage.getItem("utilisateur")).id,
						international: infosBancaireInter.international,
						titulaire: infosBancaireInter.titulaire,
						nom_banque: infosBancaireInter.nom_banque,
						n_compte: infosBancaireInter.n_compte,
						domiciliation: infosBancaireInter.domiciliation,
						iban: this.iban,
						swift: infosBancaireInter.swift,
						tva: infosBancaireInter.tva
					}
				})
				.then(data => {
					var message = "";
					if (data.data.addOrUpdateInfosBanc != null) {
						this.infosBancaireInter = data.data.addOrUpdateInfosBanc
						if (this.infosBancaireInter.id == null) {
							message = this.$t("apiresponse.bankinginfocreated");
						} else {
							message = this.$t("apiresponse.bankinginfoupdated");
						}

						this.$notify({
							type: "success",
							verticalAlign: "top",
							horizontalAlign: "right",
							message: message,
							duration: 5000
						});
					}else{
						message = this.$t("apiresponse.bankinginfoerror");
						this.$notify({
							type: "warning",
							verticalAlign: "top",
							horizontalAlign: "right",
							message: message,
							duration: 5000
						});
					}
					
					this.loader = false;
				})
				.catch(error => {
					// Error
					console.error(error);
				});
		},
		substr_replace(str, replace, start, length) {
			if (start < 0) {
				// start position in str
				start = start + str.length;
			}
			length = length !== undefined ? length : str.length;
			if (length < 0) {
				length = length + str.length - start;
			}
			return (
				str.slice(0, start) +
				replace.substr(0, length) +
				replace.slice(length) +
				str.slice(start + length)
			);
		}
	}
};
</script>
