<template>
  <div>
    <card>
      <h3 class="card-title">HTML MAILING</h3>
      <div class="row">
        <div class="col-lg-4 col-md-6" v-for="(visuel, id) in listHtml" :key="visuel.id">
          <card>
            <h4 class="card-title">{{  visuel.taille + " "+ visuel.tmp.split(' ')[0] }}</h4>
            <div class="prog">
              <img v-if="visuel.hostname == ''" style="max-width: 155; max-height: 194; cursor: pointer;"
                :src="'https://www.weedoit.fr/tracking/partenaire/'+ visuel.repertoire + '/' +visuel.profil" @click="download('/apercu_template.html?idf='+ visuel.id_visuel + '&id_visuel=' + visuel.id_visuel + '&campagne=visuel_' + visuel.id_visuel + '&hostname=old')"/>
              <img v-if="visuel.hostname == 'plf'" style="width: 155; height: 194; cursor: pointer;"
                :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/'+ visuel.repertoire.replace('é','e') + '/' +visuel.profil" @click="download('',visuel)"/>
            </div>
            <h3></h3>
            <h5><b> Expéditeur : </b>
              <template> {{ visuel.expediteur }} </template>
            </h5>
            <h5><b>Objet :</b> {{ visuel.objet }} </h5>
            <h5><b>Objet :</b> {{ visuel.objet2 }}</h5>
            <h5><b>URL : </b>{{ visuel.lien }}</h5>
          </card>
        </div>
      </div>
    </card>

    <card>
      <h3 class="card-title">BANNIÈRES STANDARDS</h3>
      <center>
        <div class="col-lg-3 col-md-6">
          <card
            v-for="visuel in listBannier" :key="visuel.id"
          >
            <h4 class="card-title">{{ visuel.repertoire+": " + visuel.taille + " image - gif" }}</h4>
            <img v-if="visuel.hostname == ''" :src="'https://www.weedoit.fr/tracking/partenaire/'+ visuel.repertoire + '/' +visuel.profil" />
            <img v-if="visuel.hostname == 'plf'" :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/'+ visuel.repertoire.replace('é','e') + '/' +visuel.profil" />
          </card>
        </div>
      </center>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import gql from "graphql-tag";

export default {
  apollo: {
    list_visuel: {
      query: gql`query ($id_programme: Int!){
        list_visuel (orderBy: [{ field: "id_visuel", order: DESC }],id_programme: $id_programme, etat: "valide"){
          id_visuel
          taille
          format
          profil
          repertoire
          hostname
          expediteur
          objet
          objet2
          lien
          url
          tmp
        }
      }`,
      skip() {
        return this.SkipQuery
      },
      variables() {
        return {
          id_programme: this.PrgVisualiser.id_programme
        }
      },
      result({ data, loader, networkStatus }) {
        this.listHtml = data.list_visuel.filter(visuel => {
          return visuel.format.match("html")
        });
        this.listBannier = data.list_visuel.filter(visuel => {
          return visuel.format.match("gif")
        });
        this.SkipQuery = true
        this.SkipQuery2 = false
      }
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      PrgVisualiser: null,
      SkipQuery: true,
      SkipQuery2: true,
      listHtml: null,
      listBannier: null,
      StatsVisuel: null,
      SUMLimp:0,
      SUMclics:0,
      SUMlead:0,
      SUMtauxclick:0,
      SUMtrans:0
    }
  },
  beforeMount() {
    if ((this.PrgVisualiser = JSON.parse(localStorage.getItem('ProgrammeVisualiser'))) == null) {
      this.$router.push({name: "liste programmes My Affil Editeur"})
    } else {
      this.SkipQuery = false
      console.log(this.PrgVisualiser.id_programme)
      localStorage.removeItem("ProgrammeVisualiser")
    }
  },
  methods: {
    download(url, row) {
      if(row) {          
        window.open('/apercu_template.html?idf='+ row.id_visuel +'&id_visuel=' + row.url.split('/')[3] + '&campagne=' + row.url.split('/')[2] + '&hostname=' + row.hostname);
      }
      else
        window.open(url);
    },
  }
}
</script>

<style scoped>
  .prog {
    height:auto;
    width: 140px;
    border-radius:none;
    overflow: hidden;
    margin: 0 auto;
  }
  .prog img {
    width: 100%;
  }
  </style>